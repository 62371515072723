import './style.css';
import {Grid, Container, Button} from '@material-ui/core';
import {useEffect, useState} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {ROUTES} from '../../constants';
import {ProfileImage} from '../ProfileImage';
import {Hamburger} from '../Hamburger';
import NavBarLink from './NavBarLink';
import ButtonPretty from '../../Components/ButtonPretty';
import ButtonLogIn from '../../Components/ButtonLogIn';
import {IsSignedInContext, AuthIsInitializedContext} from '../../Components/AuthHandler';
import React, {useContext} from 'react';
import {signInWithGoogle} from '../../firebase';

var pathname = window.location.pathname;
// switch(pathname) {
//   case "/home" :
//       console.log("home");
//       break;
//   case "/game" :
//       console.log("game");
//       break;
// }
function Select(e) {
  var elems = document.querySelectorAll('.colored');
  [].forEach.call(elems, function (el) {
    el.classList.remove(' colored');
  });
  e.target.className = 'navlink colored';
}

// function Redirect(e) {
//   console.log(e);
//   var id = e.target.id
//   console.log(id);
//   if (id == "info") {
//     // props.history.push('/register');
//     console.log(e);
//   }

// }

const Header = props => {
  // wrapper div used to give padding at top of page so that page doesn't get
  // hidden under header before scrolling

  return (
    <div className="header-sizer">
      <FloatingHeader />
    </div>
  );
};

const FloatingHeader = props => {
  const isSignedIn = useContext(IsSignedInContext);
  const authInitalized = useContext(AuthIsInitializedContext);
  const [isAtTop, setIsAtTop] = useState(true);
  // scrolls to the top of the page when clicking on a new link

  function handleScroll() {
    setIsAtTop(window.scrollY === 0);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener('scroll', handleScroll, {passive: true});
  }, []);

  var state = {
    dropdownVisible: false,
  };

  // toggles the dropdown each time it is called
  function menuToggle() {
    console.log(state.dropdownVisible);
    state.dropdownVisible = !state.dropdownVisible;
  }

  const Dropdown = ({show}) => (
    <div style={{display: show ? 'inline' : 'none'}}>
      <Grid item>
        <Links direction={'column'} />
      </Grid>
    </div>
  );

  const Links = ({direction}) => (
    <Grid
      container
      direction={direction}
      justify="flex-start"
      alignItems="flex-end"
      class="wrapper"
    >
      {!authInitalized || isSignedIn ? (
        <nav>
          <label for="info" className="info">
            <NavBarLink
              id="infoLink"
              className="navlink colored"
              onClick={Select}
              to={{pathname: ROUTES.INFO}}
            >
              About Us
            </NavBarLink>
          </label>
          <label for="baseline" className="baseline">
            <NavBarLink
              id="baselineLink"
              class="navlink colored"
              onClick={Select}
              to={{pathname: ROUTES.BASELINE}}
            >
              Baseline
            </NavBarLink>
          </label>
          <label for="session" className="session">
            <NavBarLink
              id="sessionLink"
              class="navlink colored"
              onClick={Select}
              to={{pathname: ROUTES.SESSION}}
            >
              Session
            </NavBarLink>
          </label>
          <label for="community" className="community">
            <NavBarLink
              id="communityLink"
              class="navlink colored"
              onClick={Select}
              to={{pathname: ROUTES.COMMUNITY}}
            >
              Community
            </NavBarLink>
          </label>
          <label for="team" className="team">
            <NavBarLink
              id="teamLink"
              class="navlink colored"
              onClick={Select}
              to={{pathname: ROUTES.TEAM}}
            >
              Team
            </NavBarLink>
          </label>
        </nav>
      ) : (
        <nav>
          <label for="info" className="info">
            <NavBarLink
              id="infoLink"
              className="navlink colored"
              onClick={Select}
              to={{pathname: ROUTES.INFO}}
            >
              About Us
            </NavBarLink>
          </label>
          <label for="team" className="team">
            <NavBarLink
              id="teamLink"
              class="navlink colored"
              onClick={Select}
              to={{pathname: ROUTES.TEAM}}
            >
              Team
            </NavBarLink>
          </label>
        </nav>
      )}
    </Grid>
  );

  return (
    // Removed "header-sizer" class
    <Grid
      class={'header floating' + (isAtTop ? '' : ' drop-shadow')}
      container
      justify="flex-start"
      direction="column"
    >
      <Grid item>
        <Grid container justify="space-between" direction="row" alignItems="center">
          <Grid item>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <img src="Koalacademy_250.png" className="logo" alt="Koalacademy logo" />
                <div className="spacer" />
              </Grid>

              <Grid item>
                <Grid container direction="row" alignItems="flex-end">
                  <Link className="unfancy-link brandName home-link" to={{pathname: ROUTES.HOME }}>Koalacademy</Link>
                  <div className="headerLink">
                    <Links direction={'row'} />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container direction="row" alignItems="center" justify="flex-end">
              {!authInitalized || isSignedIn ? (
                <div></div>
              ) : (
                //<a href={ROUTES.CATEGORIES}><button className="Button"> Start</button></a>
                <ButtonLogIn className="login-button" text="Log In" onClick={signInWithGoogle} />
              )}
              <Grid item>
                <ProfileImage />
              </Grid>

              <Grid item>
                <div className="burger" onClick={menuToggle}>
                  <Hamburger />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dropdown show={state.dropdownVisible} />
    </Grid>
  );
};

export default Header;
