import React from 'react';
import './styles.css';
import {Card, Grid} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import {LibraryBooksOutlined, PersonOutlineRounded, SchoolOutlined} from "@material-ui/icons";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const DropButton = () => {
    const [showResults, setShowResults] = React.useState(false)
    const onClick = () => setShowResults(!showResults)
    return (
        <div>
            <div>
                <button className="prettyButton" variant="outlined" color="primary" onClick={onClick} >
                    <a className="prettyButton">How to use Koalacademy</a>

                </button>
            </div>
            { showResults ? <Results /> : null }
        </div>
    )
};

const Results = () => {
    return (
        <Grid className="Cards">
            <div className="home-card">
                <div className={"fancy-digit"}>
                    1
                </div>
                <div className={"infoButtonParagraph"}>
                    Start a Lesson
                    <br/>
                    <LibraryBooksOutlined fontSize={"large"}/>
                </div>
            </div>

            <div className="home-card">
                <div className={"fancy-digit"}>
                    2
                </div>
                <div className={"infoButtonParagraph"}>
                    Share your progress
                    <br/>
                    <PersonOutlineRounded fontSize={"large"}/>
                </div>
            </div>

            <div className="home-card">
                <div className={"fancy-digit"}>
                    3
                </div>
                <div className={"infoButtonParagraph"}>
                    Review your learning
                    <br/>
                    <SchoolOutlined fontSize={"large"}/>
                </div>
            </div>

        </Grid>
    )}

export default DropButton;