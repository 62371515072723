import { createStore, action } from 'easy-peasy'



const store = createStore({
    serial: true,
    //Actions
    toggle: action((state, bool)=>{
        state.serial = false; //only used to switch off instructions
        return state;
    }),
});

export default store;