import React, {useState, useEffect} from 'react';
import { auth } from '../../firebase';

const IsSignedInContext = React.createContext(null);
const AuthIsInitializedContext = React.createContext(null);

const AuthHandler = (props) => {
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [authIsInitialized, setAuthIsInitialized] = useState(false);
    
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setAuthIsInitialized(true);
            if (user) {
                setIsSignedIn(true);
                window.scrollTo(0,0); // scroll to top after login
            }
            else {
                setIsSignedIn(false);
            }
        });

        return unsubscribe;
    }, [setIsSignedIn, setAuthIsInitialized]);

    return (
    <AuthIsInitializedContext.Provider value={authIsInitialized} >
        <IsSignedInContext.Provider value={isSignedIn}>
            {props.children}
        </IsSignedInContext.Provider>
    </AuthIsInitializedContext.Provider>
    );
}

export default AuthHandler;
export {IsSignedInContext, AuthIsInitializedContext};