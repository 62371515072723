import { useContext, useEffect, useState } from 'react';
import { loadGraphModel } from '@tensorflow/tfjs';

function useModel(path, onLoaded) {
    const [model, setModel] = useState(null);

    useEffect(async () => {
        const model = await loadGraphModel(path);
        setModel(model);
    }, []);

    useEffect(() => {
        if (model !== null) {
            onLoaded();
        }
    }, [model]);

    return model;
}

export { useModel };