import {Grid, Box, CircularProgress, Typography} from '@material-ui/core'
import './styles.css';

export function Loading(props) {
    return (
        <Grid className="loading" container direction="column" align="center">
            <Grid item xs={12}>
                <Box position="relative" display="inline-flex">
                    <CircularProgress size={props.size ? props.size : 100}/>
                    <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <img id="logo" src="Koalacademy_250.png" alt="Koalacademy logo"/>
                    </Box>
                </Box>
            </Grid>
            <Grid>
                <Typography id="loadingText">
                    {props.text}
                </Typography>
            </Grid>
        </Grid>
    );
}


export default Loading;