import './styles.css';
import { Loading } from '../../Components/Loading';
import {useEffect} from 'react';
import { auth } from '../../firebase';
import { ROUTES } from '../../constants';
import Header from '../../Components/Header';

const LogoutPage = (props) => {
  // DEFAULT PROPS

  // STATE
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        auth.signOut();
      }
      else {
        props.history.push(ROUTES.HOME); // redirect to login page after logout
      }
    });

    return () => {
      unsubscribe(); // must use this to prevent redirect before unmounting component
    }
  }, [props.history]); // [] as second argument means it is only called on initial load. Include any dependencies

  // Render function
  return (
    <div>
      <Header/>
      <Loading text="Logging out..."/>
    </div>
  );
}

export default LogoutPage