import { TrialTypes } from './structs';

function getRandomInt(min, max) {
    return Math.floor((Math.random() * Math.floor(max)) + min);
}

function getTrialInfo(trialSchedule) {
    const nTrialsTotal = trialSchedule.length;
    var nFalseTrials = 0;
    for (var i = 0; i < nTrialsTotal; i++){
        if (trialSchedule[i] == TrialTypes.badMatch){
            nFalseTrials++;
        }
    }
    const nAssosRequired = nTrialsTotal + nFalseTrials;
    return {
        nTrialsTotal: nTrialsTotal,
        nAssosRequired: nAssosRequired
    }
}

/**
 * Generate a list of unique numbers.
 * @param {Number} nToChoose how many indices to generate
 * @param {Number} min minimum index possible
 * @param {Numer} max maximum index possible
 */
function getRandomUniqueIndicesInRange(nToChoose, min, max) {
    //console.log("Debug: getRandomUniqueIndicesInRange called. (nToChoose: " + nToChoose + ", min: " + min + ", max: " + max + ")");
    if (nToChoose > max){
        throw 'More words requested than exists in the database!';
    }
    let random = [];
    for (let i = 0; i < nToChoose; /*empty*/) {
        let temp = getRandomInt(min, max);
        if (random.indexOf(temp) === -1) {
            random.push(temp);
            i++;
        }
    }
    //console.log("Debug: getRandomUniqueIndicesInRange returned: " + random);
    return random;
};

export { getRandomUniqueIndicesInRange, getTrialInfo };