import {Card, Box} from '@material-ui/core';
import './styles.css';
import CardContent from '@material-ui/core/CardContent';
import Header from '../../Components/Header';
import CardMedia from '@material-ui/core/CardMedia';
import Footer from '../../Components/Footer';
import {ROUTES} from '../../constants';
import React from 'react';
import ButtonPretty from '../../Components/ButtonPretty';
import YTPlayer from "../../Components/YoutubeVideo";

const Info = () => {
  return (
    <div class="infoBody">
      <Header />

      <Box className="content">
        <Box className="mainContent">
          <Box display="flex" flexDirection="column" justify="center" alignItems="center" flex="1">
            <h1>Koalacademy</h1>

            <p>
              This project was facilitated by NeurAlbertaTech, Alberta's premier Neurotechnology
              innovator network
            </p>

            <ButtonPretty link="https://forms.gle/NxVA1VBqu8VrxjzT6" text="Join Our Newsletter" />
            <ButtonPretty link={ROUTES.TEAM} text="Meet The Team" />
            <ButtonPretty link={'https://natuab.ca/'} text="NeurAlbertaTech" />
          </Box>

          <Box display="flex" flexDirection="column" justify="center" alignItems="center" flex="2">
            <h2>Mission Statement</h2>

            <p>
              We aim to improve language learning, with a particular focus on increasing
              accessibility through the use of commercial BCI’s.
            </p>

            {/*Playing our Youtube Video*/}
            <YTPlayer id={"ig1y2seIu7Y"}/>

          </Box>
        </Box>

        <Box display="flex" justify="center">


          {/*Steam requirements Card*/}
          <Card className={'steamCard'}>
            <CardContent>
              <div className={'steamText'}>
                <div className={'steamTitle'}>System Requirements</div>

                <hr />


                <Box className="steamRequirements">
                  <div>

                    <div className={'steamTinyHeading'}>
                      Minimum:
                      <br />

                    </div>
                    Requires a 64-bit processor operating system and a OpenBCI headset

                    <br />
                    <ul>

                      <li>
                        <em>OS: </em>
                        Windows 7
                        <br />
                      </li>

                      <li>
                        <em>Processor: </em>Intel i5 or AMD FX-6300
                        <br />
                      </li>

                      <li>
                        <em>Memory: </em>8 GB
                        <br />
                      </li>

                      <li>
                        <em>Graphics: </em>Nvidia GeForce GTX 770 2GB or AMD Radeon R9 280 3GB
                        <br />
                      </li>

                      <li>
                        <em>Network: </em>Broadband Internet connection
                        <br />
                      </li>

                      <li>
                        <em>Storage: </em>150 GB available space
                        <br />
                      </li>

                      <li>
                        <em>Sound Card: </em>Direct X Compatible
                        <br />
                      </li>

                      <li>
                        <em>Headset:</em>
                        Any of the headsets offered by <a href = {"https://shop.openbci.com/collections/frontpage"} >OpenBCI</a> would work with the application
                        <br/>
                      </li>

                    </ul>








                  </div>

                  <div>
                    <div className={'steamTinyHeading'}>
                      Recommended:
                      <br />

                    </div>
                    Requires a 64-bit processor operating system and a OpenBCI headset

                    <br />

                    <ul>

                      <li>
                        <em>OS: </em>Windows 10
                        <br />
                      </li>

                      <li>
                        <em>Processor: </em>Intel i7 or AMD Ryzen 5
                        <br />
                      </li>

                      <li>
                        <em>Memory: </em>12 GB
                        <br />
                      </li>

                      <li>
                        <em>Graphics: </em>Nvidia GeForce GTX 1060 6GB or AMD Radeon RX 480 4GB
                        <br />
                      </li>

                      <li>
                        <em>Network: </em>Broadband Internet connection
                        <br />
                      </li>

                      <li>
                        <em>Storage: </em>150 GB available space
                        <br />
                      </li>

                      <li>
                        <em>Sound Card: </em>Direct X Compatible
                        <br />
                      </li>
                      <li>
                        <em>Headset: </em>We recommend the <a href = {"https://shop.openbci.com/collections/frontpage/products/ultracortex-mark-iv"} >UltraCortex mark 4</a>
                        <br/>
                      </li>
                    </ul>

                  </div>
                </Box>
              </div>
              {/*  One continuous card of information */}
            </CardContent>
          </Card>
        </Box>
      </Box>

      <Footer />
    </div>
  );
};

export default Info;
