import { Grid } from '@material-ui/core';
import { DataSourceTypes } from './index';
import ButtonPretty from '../../Components/ButtonPretty';

const SelectBrainDataSource = (props) => {
    return (
        <div className='setup-openbci'>
            <div className="intermediate-session-main-container">
                {props.hadError
                    ? <Grid className="error-message" item xs={12}>
                        <span>Error in the setup, please try again.</span>
                    </Grid>
                    : null
                }
                <Grid item xs={12} sm={6}>
                    <button className='prettyButton openbci-selection' onClick={() => props.setCallback(DataSourceTypes.OPEN_BCI)}>
                        OpenBCI
                    </button>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <button className='prettyButton openbci-selection' onClick={() => props.setCallback(DataSourceTypes.MOCK)}>
                        Mock
                    </button>
                </Grid>
            </div>
        </div>
    );
}

export default SelectBrainDataSource;