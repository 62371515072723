const TrialTypes = {
    goodMatch: 1,
    badMatch: 0,
}
  
const AnswerChoices = {
    timeout: -1,
    false: 0,
    true: 1,
}

function isCorrect(answer, trialType) {
    switch (trialType) {
        case TrialTypes.goodMatch:
            return answer === AnswerChoices.true;
        case TrialTypes.badMatch:
            return answer === AnswerChoices.false;
    }
}

export { TrialTypes, AnswerChoices, isCorrect }