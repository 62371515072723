import Header from '../../Components/Header';
import './styles.css';
import MemberCard from './member_card';

const TeamPage = (props) => {
    return(
        <div id='team-page'>
            <Header/>
            <div className='title'>
                <h1 className='meet'>Koalacademy Team</h1>
                <div className='dev-mention'>Proudly facilitated by <a href="http://natuab.ca"
                                                                       target="_blank">NeurAlbertaTech</a></div>
            </div>
            <div className='team-container'>
                                <MemberCard
                                    src='Eden_Redman.png'
                                    name='Eden Redman'
                                    position='Project Lead'
                                    blurb='Eden is responsible for managing workflow within and across project sub-teams, talent recruitment and on-boarding, ensuring project milestones are met, and establishing the program architecture. In short, he ensures the team is collaborating effectively and leads or assists with various hands-on aspects of the project.'
                                    linkedin='https://www.linkedin.com/in/eden-redman-neuro/'
                                />
                                <MemberCard
                                    src='Landon_Fuhr.png'
                                    name='Landon Fuhr'
                                    position="Senior Full Stack Developer"
                                    blurb="Landon works on the front- and back-end development. This includes working and collaborating on database and authentication, cloud communication, design and UX, and OpenBCI interfacing. He also refactors code as necessary to ensure a maintainable code base."
                                    linkedin='https://www.linkedin.com/in/landon-fuhr-8522031b7/?originalSubdomain=ca'
                                />
                                <MemberCard
                                    src='Harrison_Fah.png'
                                    name='Harrison Fah'
                                    position="Senior Full Stack Developer"
                                    blurb="Harrison has worked on setting up the connection between the openBCI headset and chrome as well as parsing/converting the incoming data. He also worked on integrating the openBCI with react in the form of a baseline task/game and communicating with the cloud database."
                                    linkedin='https://www.linkedin.com/in/harrison-fah-3858b7204/'
                                />
                                <MemberCard
                                    src='Cameron_Hildebrandt.png'
                                    name='Cameron Hildebrandt'
                                    position="Senior Front End Developer"
                                    blurb="Cameron is working on multiple front end aspects of the project, ranging from creating certain pages throughout the application to adding final touches to UI components. He is also responsible for helping with the front to back end connection for the development of the baseline game."
                                    linkedin='{some linkedin link}'
                                />
                                <MemberCard
                                    src='Matthew_Danyluik.png'
                                    name='Matthew Danyluik'
                                    position="Data Analyst"
                                    blurb="Matthew focuses on server-side analytics, which includes training a machine learning model based on activity in the baseline task to make predictions on learning outcomes during the real game. He will also help guide experimental design to evaluate how effective our procedure is."
                                    linkedin='https://www.linkedin.com/in/matthew-danyluik/'
                                />
                                <MemberCard
                                    src='Nicole_Wlasitz.png'
                                    name='Nicole Wlasitz'
                                    position="Junior Front End Developer"
                                    blurb="Nicole works on basic front-end development of the project. She is responsible for implementing changes to the wireframe within the project and ensuring formatting is consistent across all pages. "
                                    linkedin='https://www.linkedin.com/in/nicole-wlasitz-she-her-0a523713b/'
                                />
                                <MemberCard
                                    src='dania_villarreal.png'
                                    name='Dania Villarreal'
                                    position="Front End Developer"
                                    blurb="Dania’s work focused on the front end aspects of the project, including setting up some pages and their layout; as well as providing some of the artwork to the website’s design. She is currently the representative for the front end team and helps ensure that tasks for front end development are designated properly and carried out."
                                    linkedin='https://www.linkedin.com/in/dania-villarreal-6931641a5/'
                                />
                                <MemberCard
                                    src='Ben_Wang.png'
                                    name='Ben Wang'
                                    position="Full Stack Developer"
                                    blurb="Ben works on front-end and back-end development. This includes connecting information from the website’s backend into the user’s display. He will also work on pre-processing data received by our interfacing software."
                                    linkedin='{some linkedin link}'
                                />
                                <MemberCard
                                    src='William_Chen.jpeg'
                                    name='William Chen'
                                    position="Game Developer"
                                    blurb="William works on the Unity game and its inner workings. He implements functions such as connecting the game to React, receiving questionnaires and timings for the Unity front end and providing real time feedback for React."
                                    linkedin='{some linkedin link}'
                                />
                                <MemberCard
                                    src='Tanyaradzwa_Gozhora.png'
                                    name='Tanyaradzwa Gozhora'
                                    position="Junior Front End Developer"
                                    blurb="Tanya works on the frontend of the project. In particular, he constructs components that make the site more intuitive to use. He also makes sure that pages remain responsive across different screen sizes and general bug fixes."
                                    linkedin='https://www.linkedin.com/in/tanyaradzwa-gozhora-8941b519a/'
                                />
                                <MemberCard
                                    src='Julie_Pilz.jpeg'
                                    name='Julie Pilz'
                                    position='Game Developer'
                                    blurb='Julie works on the Game team. She is helping the team with the Unity side of the project, as well as providing art assets.'
                                    linkedin=''
                                />
                                <MemberCard
                                    src='Khaled_Al-Amoodi.jpeg'
                                    name='Khaled Al-Amoodi'
                                    position='Software Developer'
                                    blurb='While Khaled is mainly a front-end developer (designing and creating responsive features and pages), he also works on other aspects of Koalacademy. Other tasks he handles are data analysis and some game development.'
                                    linkedin='https://www.linkedin.com/in/alamoodi/'
                                />
                                <MemberCard
                                    src='Shelly_Xue.jpeg'
                                    name='Shelly Xue'
                                    position='Front End Developer'
                                    blurb='Shelly’s works focused on front-end development. She develops fluid layouts to ensure the website is usable across device types with varying screen dimensions. She also works on fetching data from databases and displaying it on web pages.'
                                    linkedin='https://www.linkedin.com/in/y-xue/'
                                />
                                <MemberCard
                                    src='Madihah_Khan.jpeg'
                                    name='Madihah Khan'
                                    position='Animator'
                                    blurb='Madihah works with the Design and Communications team. She uses software Blender to animate 3D animations of flowcharts that represent the Koalacademy Project for everyone to easily understand and follow.'
                                    linkedin=''
                                />
                                <MemberCard
                                    src='Faiyaz_Ahmed.jpeg'
                                    name='Faiyaz Ahmed'
                                    position='Data Analyst'
                                    blurb='Faiyaz focuses on server-side analytics and the machine learning architecture. He will research machine learning pipelines and help implement the feature selection algorithm of this project. He will also train and evaluate the machine learning models.'
                                    linkedin='https://www.linkedin.com/in/faiyaz-1/'
                                />
                                <MemberCard
                                    src='Nicholas_Mellon.jpeg'
                                    name='Nicholas Mellon'
                                    position='Animator'
                                    blurb='Nicholas works with the Design and Communications team. He uses Blender software to create 3D animations of flowcharts and models of the Koalacademy interface, so that the general public has a better understanding of the project.'
                                    linkedin='https://www.linkedin.com/in/nicholasmellon/'
                                />
                                <MemberCard
                                    src='Aditya_Shah.jpeg'
                                    name='Aditya Shah'
                                    position='Design and Communications'
                                    blurb='Aditya works with the blender team to create animations like the flowchart.'
                                    linkedin=''
                                />
                                <MemberCard
                                    src='Baldeep_Dhada.jpeg'
                                    name='Baldeep Dhada'
                                    position='3D Animator'
                                    blurb='Baldeep creates 3D animation to showcase neurotechnology from a unique perspective.'
                                    linkedin=''
                                />
                                <MemberCard
                                    src='Dr_Kuo-Chan_Sun.jpg'
                                    name='Dr. Kuo-Chan Sun'
                                    position='---'
                                    blurb='bio'
                                    linkedin=''
                                />
                                <MemberCard
                                    src='Dr_Jacqueline_Cummine.jpeg'
                                    name='Dr. Jacqueline Cummine'
                                    position='---'
                                    blurb='bio'
                                    linkedin=''
                                />
                                <MemberCard
                                    src='default_profile.png'
                                    name='Avary Kostiw'
                                    position='---'
                                    blurb='bio'
                                    linkedin=''
                                />
                                {/* <Footer></Footer> */}
                        </div>
        </div>
    )
}

export default TeamPage;