import './styles.css';
import '../ButtonPretty/styles.css'; // Done for Button styling
import React from 'react';

function ButtonPretty(props){
    return(
        <div>
        <button className="prettyButton" variant="outlined" color="primary" onClick={props.onClick}>
            <a className="prettyButton" href={props.link}>{props.text}</a>
        </button>
        </div>
    )
}


export default ButtonPretty;