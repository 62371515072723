import './styles.css';
import { auth } from "../../firebase";
import { Loading } from "../Loading";
import { useEffect, useState } from 'react';
import {ROUTES} from '../../constants';
import { Menu, MenuItem } from '@material-ui/core';
import { useHistory } from "react-router-dom";

function getProfileURL() {
    return auth.currentUser.photoURL
        ? auth.currentUser.photoURL
        : 'default_profile.png';
}

export function ProfileImage() {
    let history = useHistory();

    const [authInitialized, setAuthInitialized] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    // Auth listener to redirect non-signed-in users (or unverified)
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setAuthInitialized(true);
        });

        return unsubscribe;
    }, []); // [] as second argument means it is only called on initial load. Include any dependencies

    if (!authInitialized) {
        return (
            <div>
                <Loading size={40}/>
            </div>
        );
    }
    else if (auth.currentUser) {
        return (
            <div id="profile-img-div">
                <img 
                onClick={() => setIsOpen(!isOpen)} 
                className="profile-img" 
                alt="Profile"
                src={getProfileURL()} />
                
                <Menu
                open={isOpen}
                anchorEl={document.getElementById("profile-img-div")}
                onClose={() => setIsOpen(false)}
                >
                <MenuItem onClick={() => history.push(ROUTES.PROFILE)}>Profile</MenuItem>
                <MenuItem onClick={() => history.push(ROUTES.LOGOUT)}>Logout</MenuItem>
                </Menu>
            </div>
        );
    }
    else {
        return null;
    }
}