const MOCK_MSG = '[MockSerial]';
const MOCK_DATA = new Uint8Array(33);

class MockPort {
    constructor() {
        this.readable = MockReadable;
        this.writable = MockWritable;
    }

    async open(options) {
        console.log(MOCK_MSG, 'Port open() called.');
    }

    async close() {
        console.log(MOCK_MSG, 'Port close() called.')
    }
}

class MockReader {
    async read() {
        await sleep(3);
        // console.log(MOCK_MSG, 'Reader read() called.');
        return {
            value: MOCK_DATA,
            done: false
        }
    }

    cancel() {
        console.log(MOCK_MSG, 'Reader cancel() called.');
    }

    releaseLock() {
        console.log(MOCK_MSG, 'Reader releaseLock() called.');
    }
}

class MockWriter {
    async write(arr) {
        console.log(MOCK_MSG, 'Writer write() called with:', arr);
    }

    releaseLock() {
        console.log(MOCK_MSG, 'Writer releaseLock() called.');
    }
}

class MockReadable {
    static getReader = () => {
        return new MockReader();
    }
}

class MockWritable {
    static getWriter = () => {
        return new MockWriter();
    }
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export default class MockSerial {
    static requestPort = async () => {
        const ok = window.confirm('You are using a serialport mock. Normally you would be prompted to pick the port with OpenBCI at this point.\n\nPress OK to continue.');
        if (ok) {
            return new MockPort();
        }
        else {
            throw new Error(MOCK_MSG + ' Cancelled during requestPort().');
        }
    }
}