import './styles.css';
import {Card, CardMedia, CardContent, Grid, Link, Box} from '@material-ui/core';
// import Button from '@material-ui/core/Button';
import React from 'react';
import {
    LibraryBooksOutlined,
    PersonOutlineRounded,
    SchoolOutlined,
    PeopleAltOutlined,
    Business,
    AccountBalance,
  } from '@material-ui/icons';

function SponsorsList(props){
    return(
        <div>
            <Grid container spacing={0} direction="row" alignItems="center" justify="center">
                
                <div className={'cardGrid'}>
                  <Card className="home-stats-card">
                    <CardContent className="home-stats-flex">
                      <PeopleAltOutlined fontSize={'large'} transform="scale(1.5)" />
                      <div className={'home-stats-text'}>
                        <span className="home-stats-number">300+</span>
                        <br />
                        Users
                      </div>
                    </CardContent>
                  </Card>
                </div>

                <div className={'cardGrid'}>
                  <Card className="home-stats-card">
                  <CardContent className="home-stats-flex">
                      <Business fontSize={'large'} transform="scale(1.8)" />
                      <div className={'home-stats-text'}>
                        <span className="home-stats-number" style={{textAlign: 'center'}}>
                          30+
                        </span>
                        <br />
                        Partners
                      </div>
                    </CardContent>
                  </Card>
                </div>

                <div className={'cardGrid'}>
                  <Card className="home-stats-card">
                    <CardContent className="home-stats-flex">
                      <AccountBalance fontSize={'large'} transform="scale(1.5)" />
                      <div className={'home-stats-text'}>
                        <span className="home-stats-number">$10,000</span>
                        <br />
                        Funding
                      </div>
                    </CardContent>
                  </Card>
                </div>
            </Grid>

        </div>
    )
}


export default SponsorsList;