import { Card, CardMedia, CardContent, Grid, Link, Box } from '@material-ui/core';
import './styles.css';
import { signInWithGoogle } from '../../firebase';
import React, { useContext } from 'react';
import { ROUTES } from '../../constants';
import Header from '../../Components/Header';
import ButtonPretty from '../../Components/ButtonPretty';
import ButtonSponsor from '../../Components/ButtonSponsor';
import HomeCard from '../../Components/HomeCard';
import SponsorsList from '../../Components/Sponsors';
import { IsSignedInContext, AuthIsInitializedContext } from '../../Components/AuthHandler';
import DropButton from '../../Components/DropDownHTU';
import Footer from '../../Components/Footer';
// import DropButton from '../../Components/DropDownUse';
import sponsor_openBci from './Images/sponsor_openBci.jpg';

// We can  use this if we want to route to other pages later
// import { History } from 'history'

const HomePage = props => {
  // DEFAULT PROPS
  // STATE
  const isSignedIn = useContext(IsSignedInContext);
  const authInitalized = useContext(AuthIsInitializedContext);

  // Render function
  return (
    <div>
      <Header />
      {/* <Grid class="home2-container"> */}
      <div className="parallax">
        {/* <div className="home2-main-content"> */}
        <Box className="flex-columns">
          <Box className="home2-mainContent">
            <Box display="flex" flexDirection="column" justify="center" alignItems="center" flex="1" className="title-container">
              <div className="home2-h2">
                Welcome to
              </div>
              <div className="home2-h1">
                Koalacademy
              </div>
              <div className="home2-h2">
                The official web-based EEG accelerated language learning platform
              </div>
              {!authInitalized || isSignedIn
                ?
                <ButtonPretty link={ROUTES.BASELINE} text="Start" />
                //<a href={ROUTES.CATEGORIES}><button className="Button"> Start</button></a>
                :
                <ButtonPretty text="Get Started" onClick={signInWithGoogle} />
              }
              <Link className="learn-more-link" href={ROUTES.INFO}>
                Learn More
              </Link>
              <div className="bullet-points">
                <div className="home2-h2">
                  Koalacademy employs adaptive learning using brain waves as input
                </div>
                <br />
                <div className="home2-h3">
                  <img src="bullet.png" className="bullet" />
                  EEG as a learning tool
                  <br />
                  <img src="bullet.png" className="bullet" />
                  achieve faster learning speeds
                  <br />
                  <img src="bullet.png" className="bullet" />
                  learning a language made easy
                </div>
              </div>
            </Box>
            <div className="neuron"></div>
            <div className="tiles"></div>
            <div className="foldercard"></div>
            <div className="neuron2"></div>
            <Box display="flex" flexDirection="column" justify="center" alignItems="center" flex="1">
              <div className="background-image"></div>
            </Box>
          </Box>
        </Box>
      </div>

      <div class="steps-container">
        <br />
        {/*Note the cards class is containing all the cards and is not an*/}
        {/*  individual card*/}
        <Grid className="card-container">
          <DropButton />
          <br />
          {/*  Code modularized, as it was getting too cluttered here*/}
          {/*  DropButton has all the code regarding the "how to use" Cards and their rendering*/}
        </Grid>
      </div>
      <div class="info-container">
        <Box className="home2-content">
          <Box className="home2-mainContent">
            <Box display="flex" flexDirection="column" justify="center" alignItems="center" flex="1">
              <div className="bci-info">
                <div className="h2">Proudly Sponsored by</div>
                <div className="sponsorButtons">
                <HomeCard></HomeCard>
                </div>


              </div>
            </Box>

          </Box>
        </Box>
      </div>
      <div class="sponsors-container">
        <div className="sponsors-info">
          <div className="h2">Backed by Numbers and Innovative Minds</div>
          <SponsorsList></SponsorsList>
          <div className="home2-h4">We'd like to thank all the collaborative minds involved in the creation of this project.</div>
        </div>
        

      </div>
      <div class="video-container"></div>
      <div class="notes-on-game-container"></div>


      <Footer />
      {/* </Grid> */}

    </div>


  );
};

export default HomePage;
