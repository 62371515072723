import './styles.css';

const SessionDone = () => {
  return (
    <>
      <h1>Session Complete!</h1>
      <div className="sessionCard">
        You collected <span className="sessionNumber">9001</span>{' '}
        <span className="sessionHighlight">Koins</span>
      </div>
      <div className="sessionCard">
        You learned <span className="sessionNumber">42</span> new words
      </div>
      <div className="sessionCard">
        You reviewed <span className="sessionNumber">21</span> words
      </div>
      <div className="sessionCard">
        You're <span className="sessionNumber">32.3%</span> (repeating, of course) faster than other
        users
      </div>
    </>
  );
};

export default SessionDone;
