import { Grid, Card, CardContent, Typography, CardHeader, Divider } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { auth, firestore } from '../../firebase';
import Chart from "react-google-charts"
import './styles.css';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Formatting from '../Formatting'
import { disposeVariables, divNoNan } from '@tensorflow/tfjs-core';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
    },
}));

const OnlyShowIf = (props) => {
    return props.if
        ? props.children
        : null;
}

const ProfileDataCard = (props) => {
    return (
        <div className="profile-data-card">
            <Grid container>
                {props.children}
            </Grid>
        </div>);
}

const ProfileDataCardSmall = (props) => {
    return (
        <div className="profile-data-card profile-data-card-small">
            <Grid container>
                {props.children}
            </Grid>
        </div>);
}

const ProfileDataCardLarge = (props) => {
    return (
        <div className="profile-data-card profile-data-card-large">
            <Grid container>
                {props.children}
            </Grid>
        </div>);
}

const CoinsCard = (props) => {
    const nCoins = props.nCoins;
    return (
        <OnlyShowIf if={nCoins}>
            <ProfileDataCardSmall>
                <span className="coinCount">{nCoins}</span>
                <div className="spacer" />
                <img src="coin.png" className="coin" alt="Coin" />
            </ProfileDataCardSmall>
        </OnlyShowIf>
    );
}

const WordsLearntCard = (props) => {
    const nWordsLearnt = props.nWordsLearnt;
    return (
        <OnlyShowIf if={nWordsLearnt}>
            <ProfileDataCardSmall>
                <span className="coinCount">{nWordsLearnt}</span>
                <div className="spacer" />
                Words<br />Learnt
            </ProfileDataCardSmall>
        </OnlyShowIf>
    );
}

const RecentWordsCard = (props) => {
    const recentChars = props.recentChars;
    return (
        <OnlyShowIf if={recentChars}>
            <ProfileDataCard>
                <span className="profile-data-header">Recently learnt</span>
                <Grid container direction="row" justify="center" alignItems="center">
                {recentChars.map((char, i) =>
                    <Typography variant="h5" className="characterSmall" key={i}>
                        {char}
                    </Typography>
                )}
                </Grid>
            </ProfileDataCard>
        </OnlyShowIf>
    );
}

function round(num) {
    return Math.round(num * 100 + Number.EPSILON) / 100
}

const AccuracyCard = (props) => {
    const accuracy = props.accuracy;
    return (
        <OnlyShowIf if={accuracy}>
            <ProfileDataCardSmall>
                <span className="coinCount">{round(accuracy)}%</span>
                <div className="spacer" />
                Average<br />Accuracy
            </ProfileDataCardSmall>
        </OnlyShowIf>
    );
}

const BestWordsCard = (props) => {
    const bestChars = props.bestChars;
    return (
        <OnlyShowIf if={bestChars}>
            <ProfileDataCard>
                <div className="profile-data-header">Your most proficient words</div>
                <div>Words you most often identify correctly</div>
                <Grid container direction="row" justify="center" alignItems="center">
                    {bestChars.map((char, i) =>
                        <Typography variant="h2" className="characterMedium" key={i}>
                            {char}
                        </Typography>
                    )}
                </Grid>
            </ProfileDataCard>
        </OnlyShowIf>
    );
}

function performanceToGraphableData(performanceArray) {
    const data = [["Date", "Koins"]];
    performanceToArray(performanceArray, data);
    return data;
}

function performanceToArray(performanceArray, array) {
    for (let event of performanceArray) {
        array.push(sessionDataToArray(event));
    }
    array.sort((a, b) => a[0] - b[0]);
}

function sessionDataToArray(sessionDataMap) {
    return [sessionDataMap.date.toDate(), sessionDataMap.score]
}

function sessionDataToMap(sessionDataArray) {
    return {
        date: sessionDataArray[0],
        score: sessionDataArray[1],
    }
}

const AllPerformanceCard = (props) => {
    function findClickedPoint(str) {
        const parts = str.split("#");
        if (parts[0] === "point" || parts[0] === "pointsensitivityarea") {
            return Number(parts[2]);
        }
        else {
            return null;
        }
    }

    function onClickCallback(num) {
        setShowDetails(num !== null);
        if (num !== null) {
            setGraphIndex(num);
        }
    }

    const [graphIndex, setGraphIndex] = useState(null);
    const [showDetails, setShowDetails] = useState(false);
    const userData = props.userData;
    const dataToGraph = performanceToGraphableData(userData.allPerformance);
    return (
        <OnlyShowIf if={userData.allPerformance}>
            <ProfileDataCardLarge>
                {/* <div container className="profile-performance-card"> */}
                <Chart
                    width="100%"
                    chartType="LineChart"
                    data={dataToGraph}
                    options={{
                        curveType: 'function', // smooth curve
                        lineWidth: 5,
                        fontName: 'Righteous',
                        pointSize: 10,
                        hAxis: {
                            gridlines: {
                                color: 'none',
                            },
                            format: 'MMM d',
                        },
                        vAxis: {
                            title: 'Koins',
                            baseline: 0, // min 0 and black line
                            gridlines: {
                                color: 'none',
                            },
                        },
                        colors: ['#48B4BD'],
                        legend: 'none',
                        chartArea: { width: "80%", height: "75%" }
                    }}
                    chartEvents={[
                        {
                            eventName: "ready",
                            callback: ({ chartWrapper, google }) => {
                                const chart = chartWrapper.getChart();
                                google.visualization.events.addListener(chart, "click", e => {
                                    const num = findClickedPoint(e.targetID);
                                    onClickCallback(num);
                                });
                            }
                        }
                    ]}
                />
                {/* </div> */}
                {showDetails
                    ? <DetailedResults sessionData={dataToGraph[graphIndex + 1]} />
                    : <span className="hint">Select a session on the graph to view details</span>
                }
            </ProfileDataCardLarge>
        </OnlyShowIf>
    );
}

const DetailedResults = (props) => {
    var sessionData = props.sessionData;
    if (!sessionData) {
        return null;
    }
    sessionData = sessionDataToMap(sessionData);
    const dateOptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', time: 'short' };
    return (
        <Grid container className="session-details">
            <Grid item padding={3} xs={12}>
                <span className="profile-data-header">{sessionData.date.toLocaleString("en-US", dateOptions)}</span>
                <br />
                {sessionData.date.toLocaleTimeString()}
            </Grid>
            Koins: {sessionData.score.toString()}
        </Grid>
    );
}

const SummaryCards = (props) => {
    const userData = props.userData;
    return (
        <div className="profile-small-card-container">
            <CoinsCard nCoins={userData.nCoins} />
            <WordsLearntCard nWordsLearnt={userData.nWordsLearnt} />
            <AccuracyCard accuracy={userData.accuracy} />
        </div>
    );
}
const WordCards = (props) => {
    const userData = props.userData;
    return (
        <div>
            <RecentWordsCard recentChars={userData.recentChars} />
            <BestWordsCard bestChars={userData.bestChars} />
        </div>
    );
}
const PieChart = (props) => {
    const userData = props.userData;
    return (
        <Grid container direction="row">
                <div className="profile-data-card pie-data-card">
                        <div className="profile-data-header">Characters most revised</div>
                        <Chart
                            width={'570px'}
                            height={'400px'}
                            chartType="PieChart"
                            fontName="Roboto"
                            colour="black"
                            fill="none"
                            loader={<div>Loading Chart</div>}
                            data={[
                                ['上', 'Popularity'],
                                ['有', 11],
                                ['我', 2],
                                ['他', 2],
                                ['是', 2],
                                ['大', 7],
                            ]}
                            options={{
                                // title: 'Characters most revised',
                                slices: {
                                    0: { color: '#E3B757' },
                                    1: { color: '#E37158' },
                                    2: { color: '#48B4BC' },
                                    3: { color: '#F7AAAA' },
                                    4: { color: '#9CD1D5' },
                                },
                            }}
                            rootProps={{ 'data-testid': '1' }}
                        />
                </div>
        </Grid>
    );
}

export function ProfileBody(props) {
    // STYLE
    const classes = useStyles();

    // FIRESTORE
    const [userData, setUserData] = useState({
        nCoins: null,
        nWordsLearnt: null,
        accuracy: null,
        recentChars: [],
        bestChars: [],
        allPerformance: [],
        recentGames: [],
    });

    const onGetDoc = (doc) => {
        let sessionIDs = doc.get('all_sesh');
        let allSessionData = [];
        // chained promises to await firebase storage
        firestore.collection('users').doc(auth.currentUser.uid).collection('session').where('__name__', 'in', sessionIDs).get()
            .then(qSnap => {
                qSnap.forEach(sesDoc => {
                    let d = sesDoc.get('date');
                    let data;
                    if (typeof (d) === "string") {
                        data = {
                            date: getRandDate(new Date('2020-12-1'), new Date('2021-3-15')),
                            score: sesDoc.get('score')
                        };
                    } else {
                        data = {
                            date: d,
                            score: sesDoc.get('score')
                        };
                    }
                    allSessionData.push(data);
                });
                let bestChar = doc.get('best_char');
                let recentChar = doc.get('recently_learned');
                firestore.collection('words').get()
                    .then(charSnap => {
                        let recentCharArray = charSnap.docs.filter(char => recentChar.includes(char.id)).map((c) => {
                            return c.get('chinese_char');
                        })
                        let bestCharArray = charSnap.docs.filter(char => bestChar.includes(char.id)).map((c) => {
                            return c.get('chinese_char');
                        })
                        console.log(recentCharArray);
                        setUserData(prevState => ({
                            ...prevState,
                            nCoins: doc.get('n_coins'),
                            nWordsLearnt: doc.get('n_words_learnt'),
                            recentChars: recentCharArray,
                            accuracy: doc.get('avg_accuracy'),
                            bestChars: bestCharArray.slice(0, 3),
                            allPerformance: allSessionData,
                        }));
                    }
                    )
            })

    }
    const onDocErr = (err) => {
        console.log(err);
    }
    const onRelease = () => {
        console.log("Release");
    }
    const onGetDocFake = (doc) => {
        console.log("Doc", doc);
        setUserData(prevState => ({
            ...prevState,
            nCoins: doc.get('n_coins'),
            nWordsLearnt: doc.get('n_words_learnt'),
            recentChars: doc.get('recently_learnt'),
            accuracy: doc.get('average_accuracy'),
            bestChars: doc.get('best_characters'),
            allPerformance: doc.get('all_performance'),
        }));
    }

    const getRandDate = (start, end) => {
        return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()))
    }
    // ON INITIAL LOAD
    useEffect(() => {
        firestore.collection('users').doc(auth.currentUser.uid).collection('summary').doc('main_summary').get()
            .then((doc) => {
                if (doc.exists) {
                    firestore.collection('users').doc(auth.currentUser.uid).collection('summary').doc('main_summary').onSnapshot(onGetDoc, onDocErr, onRelease);
                } else {
                    firestore.collection('users').doc('example-user').onSnapshot(onGetDocFake, onDocErr, onRelease);
                }
            }

            )

    }, []);

    // RENDER
    return (
        <div>
            <Formatting>

            </Formatting>

            <div className="profilepage-maincontainer ">
                    <AllPerformanceCard userData={userData} />



                <div className="profile-row">
                <div className="profile-left-column">
                    <SummaryCards userData={userData} classes={classes} />
                    <WordCards userData={userData} classes={classes} />
                </div>

                <div className="profile-right-column">
                    <PieChart userData={userData} classes={classes} />
                </div>

                {/* <Grid item sm={2} ></Grid> */}
                </div>
            </div>

            <Formatting>

            </Formatting>

        </div>

    );
}
