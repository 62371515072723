import { useStore, useStoreState } from 'easy-peasy';
import InnerIndex from './innerindex';
import { useLocation } from 'react-router-dom';


function WebSerialCheck(){
    
    
  const curr_state = useStoreState((state )=> state.serial);

if (curr_state){
    return(
        <InnerIndex/>
        )

}
else{
    return (null);
}
};

export default WebSerialCheck;