import { useState } from 'react';
import { OpenBCIContext } from './index';

const ValidateOpenBCISetup = (props) => {
    const [isSetup, setIsSetup] = useState(false);

    async function performSetup(brainDataSource) {
        console.log("Debug: Validating brain data source. (isMock: " + brainDataSource.isMock + ")");
        try {
            if (!brainDataSource.isMock){
                console.log("Debug: Requesting port.");
                await navigator.serial.requestPort();
                console.log("Debug: Port connected.");
            }
            brainDataSource.device.subscribeToRawSamples(brainDataSource.isMock);
            setIsSetup(true);
        }
        catch (err) {
            props.resetCallback();
        }
    }

    return <OpenBCIContext.Consumer>
    {brainDataSource => {
        if (!isSetup) {
            performSetup(brainDataSource);
            return null;
        }
        return props.children;
    }}
    </OpenBCIContext.Consumer>
}

export default ValidateOpenBCISetup;