import { firestore } from '../../firebase';
import { getRandomUniqueIndicesInRange } from './utils';

async function loadRandomWordAssos(nAssosRequired) {
    //console.log("Debug: loadRandomWordAssos called.");
    const databaseSize = await getNWordAssos();
    let wordChoices = getRandomUniqueIndicesInRange(nAssosRequired, 1, databaseSize);
    return await loadWordAssos(nAssosRequired, wordChoices);
}

async function getNWordAssos() {
    //console.log("Debug: getNWordAssos called.");
    return firestore.collection('admin')
      .doc("stats")
      .get()
      .then((docRef) => {
        return docRef.data().wordCount; // total number of words
      });
}

async function getPreprocessingParam(){
    return firestore.collection('admin')
        .doc("preprocessing")
        .get()
        .then((docRef) => {
            return docRef.data()
          });
}

async function getTimingInfo(){
    var timingRef = firestore.collection("admin").doc("parameters_baseline_session").collection("react_word_association_1").doc("00001");
    var docSnapshot = await timingRef.get();
    var docData = docSnapshot.data();

    if (docData != undefined){

        var docDic = {};
        docDic.id = docSnapshot.ref.toString();
        docDic.iti_low = docData["iti_low"];
        docDic.iti_high = docData["iti_high"];
        docDic.num_trials = docData["num_trials"];
        docDic.num_blocks = docData["num_blocks"];
        docDic.trial_times = docData["trial_times"];

        console.log("docDic: " + JSON.stringify(docDic));

        return docDic;

    }
    else{

      throw new Error("Error: Document doesn't exist");

    }
}

async function loadWordAssos(numTotalWords, wordChoices) {
    //console.log("Debug: loadWordAssos called.")
    // firestore only lets you query "in" with up to 10, so if it is
    // more than 10, make multiple queries and concatenate results
    const MAX_PER_QUERY = 3;
    var arr = [];
    if (numTotalWords <= MAX_PER_QUERY) {
        arr = await loadWordAssosSmall(wordChoices);
    }
    else {
        arr = await loadWordAssosLarge(numTotalWords, wordChoices, MAX_PER_QUERY);
    }
    return arr;
}

async function loadWordAssosLarge(numTotalWords, wordChoices, wordsPerQuery) {
    // make multiple queries and join results together.
    var arr = [];
    for (var i=0; i < numTotalWords; i+=wordsPerQuery) {
        var choicesSubset = wordChoices.slice(i,i+wordsPerQuery);
        var subset = await loadWordAssosSmall(choicesSubset);
        arr = arr.concat(subset);
    }
    return arr;
}

async function loadWordAssosSmall(wordChoices) {
    var arr = [];
    var wordsRef = firestore.collection("words");
    var querySnapshot = await wordsRef.where("pk","in",wordChoices).get();
    if (querySnapshot.docs.length === wordChoices.length) {
        for (let doc of querySnapshot.docs) {
        arr.push(doc.data());
        }
    }
    else{
        throw new Error("Expected " + wordChoices.length.toString() + " documents, got" + querySnapshot.length.toString() + ". Primary keys: " + wordChoices.toString())
    }
    return arr;
}

export { loadRandomWordAssos, getTimingInfo };