import { useEffect, useState } from 'react';

function getWindowDimensions() {
    const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
    return {
        windowWidth,
        windowHeight
    };
}
  
export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

export function useDetectClickOutside(ref, onClickOutside) {
    useEffect(() => {
      function handleClick(event) {
          if (ref.current && !ref.current.contains(event.target)) {
              onClickOutside(ref.current);
          }
      }
  
      document.addEventListener("mousedown", handleClick);
      return () => {
          document.removeEventListener("mousedown", handleClick);
      };
    }, [ref]);
  }