export default class ChunkProcessor {
    constructor() {
      this.buffer = null;
      this.firstEightChannels = null;
    }
  
    /**
     * Process a chunk of bytes into a decimal value for each channel
     * @param {Uint8Array} chunk - array of bytes containing the values from a number of samples.
     * @returns {Array} an array of channel values
     */
    processChunk(chunk){
      var chunkData = [] 

      if (chunk == null || chunk == undefined){
        return [];
      }
      if (this.buffer != null){  // if the buffer has values from last processing, concatenate to the start of this chunk
        var tempChunk = new Uint8Array(this.buffer.length + chunk.length);
        tempChunk.set(this.buffer);
        tempChunk.set(chunk, this.buffer.length);
        chunk = tempChunk;
        this.buffer = null;
      }
  
      for (var i = 0; i < chunk.length; i+= 33){  // Loops for each sample, look at https://docs.openbci.com/Cyton/CytonDataFormat/ for formatting
  
        if (chunk.length - i < 33){ //if incomplete sample, write remaining data to buffer for next processing
          this.buffer = chunk.slice(i, chunk.length)
          break;
        }
  
        var currentData = []
  
        for (var j = 0; j < 8; j++){  //converts each group of 3 bytes for a channel to its decimal value
          var k = i + 2 + (j*3);
          currentData[j] = this._interpret24bitAsInt32(chunk.slice(k, k+3)); //converts 24 bit signed integer to 32 bit signed
        }
  
        if (this.firstEightChannels != null){  // if this is the second set of 8 channels, concatenate with previous 8
          var sampleData = this.firstEightChannels.concat(currentData);
          
          chunkData[Math.floor((i/33)/2)] = sampleData;
  
          this.firstEightChannels = null;
        }
        else{ // if this is the first set of 8 channels, set to firstEightChannels
          this.firstEightChannels = currentData;
        }

      }
  
      return chunkData;
    }
  
    /**
     * Converts a 24 bit signed integer to a 32 bit signed integer
     * @param {Uint8Array} byteArray - a Uint8Array with 3 bytes
     * @return {Number} a 32 bit signed integer representing the channel value
    */
    _interpret24bitAsInt32(byteArray) {   
      var newInt = (  
      ((0xFF & byteArray[0]) << 16) |  
      ((0xFF & byteArray[1]) << 8) |   
      (0xFF & byteArray[2])  
      );  
      if ((newInt & 0x00800000) > 0) {  
        newInt |= 0xFF000000;  
      } else {  
        newInt &= 0x00FFFFFF;  
      }  
      return newInt;  
    } 
  }