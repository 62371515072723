import Header from "../../Components/Header";
import './styles.css';
import LeaderboardCards from "./leaderboardCards";
import {auth, firestore} from '../../firebase';
import Grid from "@material-ui/core/Grid";
import {useEffect, useState} from "react";
import {FacebookShareButton, TwitterShareButton, FacebookIcon, TwitterIcon} from "react-share";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const CommunityPage = () => {
    const [userData, setUserData] = useState();
    const [sortedBy, setSortedBy] = useState();
    const [userListState, setUserListState] = useState();
    const [statsListState, setStatsListState] = useState();
    const [currentUserData, setCurrentUserData] = useState();
    const [currentUserRank, setCurrentUserRank] = useState();
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        let userList = [];
        let statsList = [];
        let dataList = [];

        /*** for testing: change to 0 if done testing ***/
            // let currentUser_has_stat = 0;
        let currentUser_has_stat = 1;

        firestore.collectionGroup("summary").get().then((querySnapshot) => {
            if (querySnapshot) {
                querySnapshot.forEach((doc) => {
                    const path = doc.ref.path.split('/');
                    if (path[0] === "users") {
                        const dataRetrieved = doc.data();
                        if (doc.id === "start_data") {
                            userList.push({
                                userID: path[1],
                                displayName: dataRetrieved.displayName,
                                photoURL: dataRetrieved.photoURL,
                            })
                        } else if (doc.id === "main_summary") {
                            if (path[1] === auth.currentUser.uid) {
                                statsList.push({
                                    userID: path[1],
                                    avg_accuracy: dataRetrieved.avg_accuracy,
                                    n_words_learnt: dataRetrieved.n_words_learnt,
                                    n_coins: dataRetrieved.n_coins,
                                    avg_score: dataRetrieved.avg_score,
                                    is_currentUser: 1,
                                })
                                currentUser_has_stat = 1;
                            } else {
                                statsList.push({
                                    userID: path[1],
                                    avg_accuracy: dataRetrieved.avg_accuracy,
                                    n_words_learnt: dataRetrieved.n_words_learnt,
                                    n_coins: dataRetrieved.n_coins,
                                    avg_score: dataRetrieved.avg_score,
                                    is_currentUser: 0,
                                })
                            }
                        }
                    }
                });

                /*** for testing: delete if done testing ***/
                statsList.push({
                    userID: auth.currentUser.uid,
                    avg_accuracy: 200,
                    n_words_learnt: 200,
                    n_coins: 5000,
                    avg_score: 200,
                    is_currentUser: 1,
                })
                /*** ***/

                if (statsList && userList) {
                    setSortedBy("Average Score")
                    // when initializing the ranking, sort the leaderboard by avg_score
                    statsList.sort((a, b) => (a.avg_score < b.avg_score) ? 1 : ((b.avg_score < a.avg_score) ? -1 : 0))
                    // associate stats with users
                    statsList.forEach(function (stats, i) {
                        userList.forEach(function (users) {
                            if (stats.userID === users.userID) {
                                dataList.push(
                                    <LeaderboardCards
                                        className="cards"
                                        user_name={users.displayName}
                                        profile_pic={users.photoURL}
                                        avg_score={stats.avg_score}
                                        koins={stats.n_coins}
                                        words_learnt={stats.n_words_learnt}
                                        accuracy={stats.avg_accuracy}
                                        rank={i + 1}
                                        sorted_by = {'Average Score'}
                                        key={i}
                                    />)
                                if (stats.is_currentUser) {
                                    setCurrentUserRank(i + 1)
                                    setCurrentUserData(
                                        <LeaderboardCards
                                            user_name={users.displayName}
                                            profile_pic={users.photoURL}
                                            avg_score={stats.avg_score}
                                            koins={stats.n_coins}
                                            words_learnt={stats.n_words_learnt}
                                            accuracy={stats.avg_accuracy}
                                            rank={i + 1}
                                            sorted_by = {'Average Score'}
                                            key={i}
                                        />
                                    )
                                }
                            }
                        })
                    })
                }

                if (!currentUser_has_stat) {
                    setCurrentUserData(
                        <div>You are not on the leaderboard</div>
                    )
                }
            }
            setUserData(dataList);
            setUserListState(userList);
            setStatsListState(statsList);
        })
    }, []);

    const shareMessage = "Currently ranked #" + currentUserRank + " on the leaderboard. " +
        "Come see if you can beat my rank!";
    const hashtags = "#Koalacademy"

    function sortLeaderboardByAvgScore(){
        const statsList = statsListState;
        if(statsList){
            statsList.sort((a, b) => (a.avg_score < b.avg_score) ? 1 : ((b.avg_score < a.avg_score) ? -1 : 0))
            console.log(statsList)
            leaderboardCardsGenerator(statsList, 'Average Score');
            setAnchorEl(null);
        }
    }

    function sortLeaderboardByKoins(){
        const statsList = statsListState;
        if(statsList){
            statsList.sort((a, b) => (a.n_coins < b.n_coins) ? 1 : ((b.n_coins < a.n_coins) ? -1 : 0))
            console.log(statsList)
            leaderboardCardsGenerator(statsList, 'Koins');
            setAnchorEl(null);
        }
    }

    function leaderboardCardsGenerator(statsList, sorted_by){
        const userList = userListState;
        let dataList = [];
        setSortedBy(sorted_by);
        if(statsList && userList){
            // associate stats with users
            statsList.forEach(function (stats, i) {
                userList.forEach(function (users) {
                    if (stats.userID === users.userID) {
                        dataList.push(
                            <LeaderboardCards
                                className="cards"
                                user_name={users.displayName}
                                profile_pic={users.photoURL}
                                avg_score={stats.avg_score}
                                koins={stats.n_coins}
                                words_learnt={stats.n_words_learnt}
                                accuracy={stats.avg_accuracy}
                                rank={i + 1}
                                sorted_by = {sorted_by}
                                key={i}
                            />)
                        if (stats.is_currentUser) {
                            setCurrentUserRank(i + 1)
                            setCurrentUserData(
                                <LeaderboardCards
                                    user_name={users.displayName}
                                    profile_pic={users.photoURL}
                                    avg_score={stats.avg_score}
                                    koins={stats.n_coins}
                                    words_learnt={stats.n_words_learnt}
                                    accuracy={stats.avg_accuracy}
                                    rank={i + 1}
                                    sorted_by = {sorted_by}
                                    key={i}
                                />)
                        }
                    }
                })
            })
            console.log(dataList);
            setUserData(dataList);
        }
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className="communityBody">
            <Header/>
            <div className="leaderboard">
                <h1>Leaderboard</h1>
                <div className="leaderboardCards">
                    {currentUserData ? (
                        <div>
                            <Grid container
                                  direction="row"
                                  justify="space-between"
                                  alignItems="flex-end">
                                <Grid item className="subtitle">
                                    Your Ranking
                                </Grid>
                                <Grid item>
                                    <FacebookShareButton
                                        url={"https://koalacademy.com/"}
                                        quote={shareMessage}
                                        hashtag={hashtags}
                                        className="marginRight5"
                                    >
                                        <FacebookIcon size={28} round={true}/>
                                    </FacebookShareButton>
                                    <TwitterShareButton className="marginLeft5"
                                                        url={"https://koalacademy.com/"}
                                                        title={shareMessage + "\n" + hashtags + " @NeurAlbertaTech"}
                                    >
                                        <TwitterIcon size={28} round={true}/>
                                    </TwitterShareButton>
                                </Grid>
                            </Grid>
                            <div className="marginBottom24">
                                {currentUserData}
                            </div>
                        </div>
                    ) : null}
                    {userData ? (
                        <div>
                            <Grid container direction="row" justify="space-between" alignItems="flex-end">
                                <Grid item className="subtitle">Ranking Overview</Grid>
                                <Grid item>
                                    <a className="sortedByButton" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                                        <Grid container direction="row" alignItems="center">
                                            Sorted by {sortedBy}<ExpandMoreIcon fontSize="large"/>
                                        </Grid>
                                    </a>
                                </Grid>
                            </Grid>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={() => {sortLeaderboardByAvgScore()}}>Sort by Average Score</MenuItem>
                                <MenuItem onClick={() => {sortLeaderboardByKoins()}}>Sort by Koins</MenuItem>
                            </Menu>
                            <div>
                                {userData}
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

export default CommunityPage;