import { useState , useEffect, useRef} from 'react';
import { loadRandomWordAssos } from './database';

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

/**
 * Handle trial counting. The trial index will never go above maxTrials-1, so you don't have to worry
 * about stopping calls to startNextTrial().
 * @param {Function} onTrialStart callback function that is run every time a new trial is started
 * @param {Number} maxTrials how many trials to count up to
 * @param {Function} onFinishCallback called if next trial is started after trialIndex has reached maxTrials-1
 */
function useTrialIndex(maxTrials, onTrialStart, onFinishCallback, assessmentPart, switchPartsCallback) {
    const [trialIndex, setTrialIndex] = useState(null);
    const [seenTrials, setSeenTrials] = useState([]);

    useEffect(() => {
        if (trialIndex === null) return; // prevent initial mount from triggering start
        // start a new trial every time trial index is incremented
        onTrialStart();
    }, [trialIndex]);

    function startFirstTrial() {
        setTrialIndex(0);
    }

    function startNextTrial(debug=undefined) {
        console.log("setting trial index:");
        console.log("seenTrials.length: " + seenTrials.length + ", seenTrials: " + JSON.stringify(seenTrials));
        var nextTrialIndex;
        console.log("trialIndex: " + trialIndex);
        if (seenTrials.length > maxTrials-1 && assessmentPart){
            console.log("finished")
            onFinishCallback();
            return;
        }
        else if (trialIndex >= maxTrials-1 && !assessmentPart){
            console.log("debug: " + debug);
            switchPartsCallback();
            var randomTrial;
            do{
                randomTrial = Math.floor(Math.random() * maxTrials);  
            } while (randomTrial == trialIndex)
            setSeenTrials(prevTrials => [...prevTrials, randomTrial]);
            console.log("returning first random trial: " + randomTrial);
            nextTrialIndex = randomTrial;
        }
        else if (assessmentPart){
            var randomTrial;
            do{
                randomTrial = Math.floor(Math.random() * maxTrials);  
            } while (seenTrials.includes(randomTrial))
            setSeenTrials(prevTrials => [...prevTrials, randomTrial]);
            console.log("returning subsequent random trial: " + randomTrial);
            nextTrialIndex = randomTrial;
        }
        else{
            nextTrialIndex = Math.min(trialIndex+1, maxTrials-1);
        }
        console.log("setting trial index to " + nextTrialIndex);
        setTrialIndex(nextTrialIndex);
    }
    return [trialIndex, startFirstTrial, startNextTrial];
}

/**
 * Only allow 1 answer per trial, storing the first answer given during the trial in 
 * an array 'answers'. You can call submitAnswer as many times as you want, but only the
 * first answer for each trial will be saved.
 * 
 * This *should* (hopefully) prevent any race conditions with keeping track of the answers.
 * 
 * This appends all new answers to an array to ensure the useEffect callback is called
 * every time an answer is submitted. useEffect is not called if you update a state with
 * the same value that it already had, so repeated answers would be missed.
 * @param {Number} trialIndex state indicating the current trial index, starting from 0
 */
function useAtomicAnswerQueue(trialIndex, assessmentPart, maxTrials) {
    const prevTrialIndex = usePrevious(trialIndex);
    const [answerQueue, setAnswerQueue] = useState([]);
    const [answers, setAnswers] = useState([]);
    useEffect(() => {
        var answer = answerQueue[answerQueue.length-1]
        console.log("answer: " + answer)
        if (trialIndex === null || answerQueue.length === 0){
           return; 
        }  // avoid initial mount callback
        // important to prevent the previous trial's answer from being saved since
        // trialIndex updating would trigger it otherwise. Without this you get the
        // previous trial's answer saved immediately upon starting the trial
        if ((trialIndex < maxTrials && prevTrialIndex < trialIndex) || answer === undefined){
            return; 
        } 
        if (answer[1] != trialIndex || answer[2] != assessmentPart){
            return;
        }
        // only add if the spot has not been previously filled
        if (answers[trialIndex] === undefined) {
            console.log("submitting answer: " + answer[0]);
            setAnswers(answers => [...answers, answer[0]]);
        }
        else if (assessmentPart && answers[trialIndex+maxTrials] === undefined){
            console.log("submitting answer: " + answer[0]);
            var tempAnswers = [...answers];
            tempAnswers[trialIndex+maxTrials] = answer[0];
            setAnswers(tempAnswers);
        }
    }, [answers, answerQueue, trialIndex]);

    function submitAnswer(newAnswer) {
        setAnswerQueue(answerQueue => [...answerQueue, newAnswer]);
    }

    return [answers, submitAnswer];
}


function useWordAssociations(nAssosRequired, onWordAssosLoaded) {
    //console.log("Debug: useWordAssociations called. (nAssosRequired: " + nAssosRequired + ")");
    const [wordAssos, setWordAssos] = useState();

    useEffect(async () => {
        // load associations on initial mount
        var allWordAssos = await loadRandomWordAssos(nAssosRequired);
        setWordAssos(allWordAssos);
    }, []);

    useEffect(() => {
        if (!wordAssos) return; // prevent initial mount from triggering start
        // callback once word associations have been loaded
        onWordAssosLoaded();
      }, [wordAssos]);

    return wordAssos;
}

export { useAtomicAnswerQueue, useWordAssociations, useTrialIndex };