import { firestore } from '../../firebase';
import { getRandomUniqueIndicesInRange } from './utils';

async function loadRandomWordAssos(nAssosRequired) {
    const databaseSize = await getNWordAssos();
    let wordChoices = getRandomUniqueIndicesInRange(nAssosRequired, 1, databaseSize);
    return await loadWordAssos(nAssosRequired, wordChoices);
}

async function getNWordAssos() {
    return firestore.collection('admin')
      .doc("stats")
      .get()
      .then((docRef) => {
        return docRef.data().wordCount; // total number of words
      });
}

async function loadWordAssos(numTotalWords, wordChoices) {
    // firestore only lets you query "in" with up to 10, so if it is
    // more than 10, make multiple queries and concatenate results
    const MAX_PER_QUERY = 3;
    var arr = [];
    if (numTotalWords <= MAX_PER_QUERY) {
        arr = await loadWordAssosSmall(wordChoices);
    }
    else {
        arr = await loadWordAssosLarge(numTotalWords, wordChoices, MAX_PER_QUERY);
    }
    return arr;
}

async function loadWordAssosLarge(numTotalWords, wordChoices, wordsPerQuery) {
    // make multiple queries and join results together.
    var arr = [];
    for (var i=0; i < numTotalWords; i+=wordsPerQuery) {
        var choicesSubset = wordChoices.slice(i,i+wordsPerQuery);
        var subset = await loadWordAssosSmall(choicesSubset);
        arr = arr.concat(subset);
    }
    return arr;
}

async function loadWordAssosSmall(wordChoices) {
    var arr = [];
    var wordsRef = firestore.collection("words");
    var querySnapshot = await wordsRef.where("pk","in",wordChoices).get();
    if (querySnapshot.docs.length === wordChoices.length) {
        for (let doc of querySnapshot.docs) {
        arr.push(doc.data());
        }
    }
    else{
        throw new Error("Expected " + wordChoices.length.toString() + " documents, got" + querySnapshot.length.toString() + ". Primary keys: " + wordChoices.toString())
    }
    return arr;
}

export { loadRandomWordAssos };