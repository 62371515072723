import './styles.css';
import {useEffect, useState} from 'react';
import {ROUTES} from '../../constants';
import {Menu, MenuItem} from '@material-ui/core';
import {useHistory} from 'react-router-dom';

export function Hamburger() {
  let history = useHistory();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div id="burger">
      <div onClick={() => setIsOpen(!isOpen)}>
        <div className="burgerPart"></div>
        <div className="burgerPart"></div>
        <div className="burgerPart"></div>
      </div>

      <Menu
        open={isOpen}
        anchorEl={document.getElementById('burger')}
        onClose={() => setIsOpen(false)}
      >
        <MenuItem onClick={() => history.push(ROUTES.INFO)}>About Us</MenuItem>
        <MenuItem onClick={() => history.push(ROUTES.BASELINE)}>Baseline</MenuItem>
        <MenuItem onClick={() => history.push(ROUTES.SESSION)}>Session</MenuItem>
        <MenuItem onClick={() => history.push(ROUTES.TEAM)}>Team</MenuItem>
        <MenuItem onClick={() => history.push(ROUTES.COMMUNITY)}>Community</MenuItem>
      </Menu>
    </div>
  );
}
