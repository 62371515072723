import {auth} from "../../firebase";
import {useEffect, useState} from 'react';
import './styles.css';
import {Loading} from '../../Components/Loading';
import { ROUTES } from '../../constants';
import Header from '../../Components/Header';
import { ProfileBody } from '../../Components/ProfileBody';
import { UserInformation } from '../../Components/UserInformation';

const ProfilePage = (props) => {
  // DEFAULT PROPS

  // STATE
  const [authInitialized, setAuthInitialized] = useState(false);

  // Auth listener to redirect non-signed-in users (or unverified)
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (!user) {
        props.history.push(ROUTES.LOGIN);
      }
      setAuthInitialized(true);
    });

    return unsubscribe;
  }, [props.history]); // [] as second argument means it is only called on initial load. Include any dependencies

  
  // RENDER
  if (!authInitialized) {
    return(<Loading/>); // loading if auth listener isn't ready yet
  }
  return (
    <div className="body">
      <Header/>
      <UserInformation/>
      <ProfileBody/>
    </div>
  );
}

export default ProfilePage;