export const ROUTES = {
  PROFILE: '/profile',
  VERIFY: '/verify',
  HOME: '/',
  LOGIN: '/login',
  SIGNUP: '/signup',
  LOGOUT: '/logout',
  CATEGORIES: '/categories',
  INFO: '/info',
  TEST: '/test',
  BASELINE: '/baseline',
  SESSION: '/session',
  ODDBALL: '/oddball',
  TEAM: '/team',
  GAME: '/game',
  UNITY: '/unity',
  COMMUNITY: '/community'
};
