import { firestore, storage } from '../firebase';
import firebase from "firebase/app";

const COLLECTION_NAME = 'compute-engine';
const ON_FUNCTION_TRIGGER_URL = 'https://us-central1-koalacademy-f8862.cloudfunctions.net/Turn_On';

export async function uploadDataAndTriggerVm(user_id, fname, dataArray, doc_id){
    try {
        let url = await uploadArrayToCloudStorage(fname, dataArray);
        await createFirestoreDocForBucketItem(user_id, url, doc_id);
        await turnOnVm();
    }
    catch(err) {
        alert('An error occured while uploading data.');
        console.error(err);
    }
}

async function uploadArrayToCloudStorage(fname, dataArray) {
    var blob = new Blob([dataArray], { type: 'text/csv;charset=utf-8;' });
    
    return storage.refFromURL('gs://koalacademy-f8862').child(fname).put(blob)
        .then(uploadSnapshot => {
            let path = uploadSnapshot.metadata.fullPath;
            console.log('Uploaded file to:', path);
            return path;
        })
        .catch(err => {
            console.error(err);
            throw new Error('Error uploading file to cloud storage.');
        });
}

async function createFirestoreDocForBucketItem(user_id, url_to_bucket_item, doc_id) { 
    const data = {
        url: url_to_bucket_item,
        date_created: firebase.firestore.FieldValue.serverTimestamp(),
        processed: false,
        uid: user_id,
        docid: doc_id
    };

    // upload document
    return firestore.collection(COLLECTION_NAME).add(data)
        .then(doc => {
            console.log(`Created doc ${doc.id} in collection ${COLLECTION_NAME}`);
        })
        .catch(err => {
            console.err('Error creating doc:', err);
            throw new Error('Could not create document');
        });
}

async function turnOnVm() {
    return fetch(
        ON_FUNCTION_TRIGGER_URL,
        {
            method: 'GET',
        })
        .then(res => {
            if (res.status === 200) {
                console.log('Started VM.');
            }
            else {
                throw new Error('Non-200 status from Vm trigger');
            }
        })
        .catch(err => {
            console.error(err);
            throw new Error('Error turning on VM.');
        });
}