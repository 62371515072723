import {Grid, Typography} from '@material-ui/core';
import React, {useState, useEffect, useContext} from 'react';
import './styles.css';
import {TrialTypes, AnswerChoices, isCorrect} from './structs';
import {useAtomicAnswerQueue, useWordAssociations, useTrialIndex} from './hooks';
import {getTrialInfo} from './utils';
import RequireOpenBCI, {OpenBCIContext} from '../../Components/RequireOpenBCI';
import {uploadDataAndTriggerVm} from '../../core/compute_firestore_integration';
import {sleep} from '../../core/utils';
import SessionInstructions from './instructions';
import SessionDone from './done';
import {auth} from '../../firebase';
import {IsSignedInContext} from '../../Components/AuthHandler';
import MustLogIn from '../../Components/MustLogIn';
import { useStoreActions, useStore, StoreProvider} from 'easy-peasy';
import store from '../../store';


// Adjust these paramaters if needed
const SHOULD_UPLOAD = false;

const showWordTimer = 200; //ms until word is shown
const showButtonTimer = 1000; //ms until buttons are shown
const showAnswerTimer = 1000; //ms until answer is shown
const allowClickTimer = 1000;

const trialLength = showWordTimer + showButtonTimer + showAnswerTimer + allowClickTimer; // ms
const interTrialDelayMin = 1000; // the lowest value for the inter trial delay in ms
const interTrialDelayVariance = 500; // the difference between the min and max inter trial delays

const numTrials = 10; // tptal number of trials
var trialSchedule = [];
// generates a random ordering of true and false tests
for (var i = 0; i < numTrials; i++) {
  if (Math.round(Math.random()) === 0) {
    trialSchedule.push(TrialTypes.goodMatch);
  } else {
    trialSchedule.push(TrialTypes.badMatch);
  }
}

function getInterTrialDelay() {
  return Math.floor(Math.random() * interTrialDelayVariance) + interTrialDelayMin;
}

const SessionPage = () => {
  return (
    <StoreProvider store={store}>
    <RequireOpenBCI>
      <SessionPageInner />
    </RequireOpenBCI>
    </StoreProvider>
  );
};

const SessionPageInner = props => {
  const openbci = useContext(OpenBCIContext);
  const isSignedIn = useContext(IsSignedInContext);

  const {nTrialsTotal, nAssosRequired} = getTrialInfo(trialSchedule);

  // UI State
  const [engWord, setEngWord] = useState('');
  const [chnChar, setChnChar] = useState('');
  const [trueBtnColor, setTrueBtnColor] = useState();
  const [falseBtnColor, setFalseBtnColor] = useState();
  const [showAnswer, setShowAnswer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isFinished, setIsFinished] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [showWords, setShowWords] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const toggle_state = useStoreActions(actions => actions.toggle);

  // Internal State
  const [falseTrialCount, setFalseTrialCount] = useState(0);
  const [assessmentPart, setAssessmentPart] = useState(false);
  const [trialIndex, startFirstTrial, startNextTrial] = useTrialIndex(
    nTrialsTotal,
    runTrial,
    onFinish,
    assessmentPart,
    switchParts
  );
  const wordAssos = useWordAssociations(nAssosRequired, onWordAssosLoaded); // load word associations on mount
  const [answers, submitAnswer] = useAtomicAnswerQueue(trialIndex, assessmentPart, nTrialsTotal);
  const [recordings, setRecordings] = useState([]);

  useEffect(() => {
    if (isLoading || showInstructions) {
      //Removing the intial instructions from the page
      toggle_state(false);
    }
    window.addEventListener('blur', onBlur);
    return () => {
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  function onBlur() {
    // TODO: reset session or send to a "timeout corner"
    console.log('blurred');
  }

  async function recordTrial() {
    if (!assessmentPart) {
      console.log('Starting trial:', trialIndex, new Date().getTime());
      let array = ['Trial Number: ' + trialIndex];
      let sampleTimes = [showWordTimer, showButtonTimer, showAnswerTimer, allowClickTimer];
      await openbci.startTrial(sampleTimes, array);
      setRecordings(prevRecordings => [...prevRecordings, array]);
    } else {
      console.log('Assessing trial:', trialIndex, new Date().getTime());
    }

    //console.log(array);
  }

  async function onWordAssosLoaded() {
    // start first trial once word associations have loaded
    setIsLoading(false);

    setShowInstructions(true);
    await sleep(10000); // allow openbci to get to consistent sampling rate
    setShowInstructions(false);

    startFirstTrial();
  }

  async function switchParts() {
    if (!assessmentPart) {
      console.log('Starting assessment');
      setAssessmentPart(true);
    }
  }

  async function onFinish() {
    await openbci.disconnect();
    console.log(recordings);
    setIsFinished(true);
    if (SHOULD_UPLOAD) {
      const uid = auth.currentUser.uid;
      uploadDataAndTriggerVm(uid, 'session/' + uid + '.raw', recordings);
    }
  }

  useEffect(() => {
    console.log(answers);
    const answer = answers[answers.length - 1];
    if (answer != AnswerChoices.timeout && !assessmentPart) {
      openbci.setTriggerToAnswer(answer, isCorrect(answer, trialSchedule[trialIndex]));
    } else if (answer != AnswerChoices.timeout && assessmentPart) {
      console.log(
        'User clicked answer: ' + isCorrect(answer, trialSchedule[trialIndex - nTrialsTotal])
      );
      var tempRecordings = [...recordings];
      tempRecordings[trialIndex - nTrialsTotal][0] =
        'Trial Number: ' +
        (trialIndex - nTrialsTotal) +
        ', Label: ' +
        isCorrect(answer, trialSchedule[trialIndex - nTrialsTotal]);
      //setRecordings(tempRecordings);
      setShowAnswer(true);
    } else if (assessmentPart) {
      console.log('User timed out on answer');
      var tempRecordings = [...recordings];
      if (!tempRecordings[trialIndex - nTrialsTotal][0].includes('Label')) {
        tempRecordings[trialIndex - nTrialsTotal][0] =
          'Trial Number: ' + (trialIndex - nTrialsTotal) + ', Label: timeout';
        //setRecordings(tempRecordings);
      }
      setShowAnswer(true);
    }
    setShowAnswer(true);
  }, [answers]);

  useEffect(() => {
    if (showAnswer) {
      if (
        (!assessmentPart && trialSchedule[trialIndex] === TrialTypes.goodMatch) ||
        (assessmentPart && trialSchedule[trialIndex - nTrialsTotal] === TrialTypes.goodMatch)
      ) {
        setTrueBtnColor('green');
        setFalseBtnColor('white');
      } else if (
        (!assessmentPart && trialSchedule[trialIndex] === TrialTypes.badMatch) ||
        (assessmentPart && trialSchedule[trialIndex - nTrialsTotal] === TrialTypes.badMatch)
      ) {
        setTrueBtnColor('white');
        setFalseBtnColor('green');
      }
    } else {
      setTrueBtnColor('white');
      setFalseBtnColor('white');
    }
  }, [showAnswer, trialIndex]);

  function runTrial() {
    setShowButtons(false);
    setShowWords(false);
    setShowAnswer(false);
    recordTrial();

    if (!assessmentPart) {
      console.log(trialIndex + ': ' + JSON.stringify(wordAssos[trialIndex]));
    }
    if (!assessmentPart) {
      if (trialSchedule[trialIndex] === TrialTypes.goodMatch) {
        setChnChar(wordAssos[trialIndex].chinese_char);
        setEngWord(wordAssos[trialIndex].eng_word);
      } else if (trialSchedule[trialIndex] === TrialTypes.badMatch) {
        setChnChar(wordAssos[trialIndex].chinese_char);
        setEngWord(wordAssos[wordAssos.length - falseTrialCount - 1].eng_word);
        setFalseTrialCount(falseTrialCount + 1);
      }
    } else {
      if (trialSchedule[trialIndex - nTrialsTotal] === TrialTypes.goodMatch) {
        setChnChar(wordAssos[trialIndex - nTrialsTotal].chinese_char);
        setEngWord(wordAssos[trialIndex - nTrialsTotal].eng_word);
      } else if (trialSchedule[trialIndex - nTrialsTotal] === TrialTypes.badMatch) {
        setChnChar(wordAssos[trialIndex - nTrialsTotal].chinese_char);
        setEngWord(wordAssos[wordAssos.length - falseTrialCount - 1].eng_word);
        setFalseTrialCount(falseTrialCount + 1);
      }
    }

    if (!assessmentPart) {
      // Async Timer
      setTimeout(() => {
        setShowWords(true);
      }, showWordTimer);
      setTimeout(() => {
        setShowButtons(true);
      }, showWordTimer + showButtonTimer);
      setTimeout(() => {
        setShowAnswer(true);
      }, showWordTimer + showButtonTimer + showAnswerTimer);
      setTimeout(() => {
        submitAnswer(AnswerChoices.timeout);
      }, trialLength);
      setTimeout(() => {
        startNextTrial();
      }, trialLength + getInterTrialDelay());
    } else {
      setTimeout(() => {
        setShowWords(true);
      }, showWordTimer);
      setTimeout(() => {
        setShowButtons(true);
      }, showWordTimer + showButtonTimer);
      setTimeout(() => {
        submitAnswer(AnswerChoices.timeout);
      }, trialLength);
      setTimeout(() => {
        startNextTrial();
      }, trialLength + getInterTrialDelay());
    }
  }
  // useEffect(()=>{
    if (!isSignedIn) {
      return <MustLogIn />;
    }
    if (isLoading || showInstructions) {
      return <SessionInstructions />;
    }
    if (isFinished) {
      return <SessionDone />;
    }
  // })
  
  return (
    <div className="CategoriesPage">
      <div className="title-container">
        <div variant="h4" className="info-title">
          Equate
        </div>
        <div className="infoParagraphEquate">Are these the same?</div>
      </div>
      {showWords ? (
        <div className="wordsContainer">
          <div className="word-card">{chnChar}</div>
          <div className="equalText">=</div>
          <div className="word-card">{engWord}</div>
        </div>
      ) : null}
      {showButtons ? (
        <div>
          {showAnswer ? (
            <div>
              {assessmentPart ? (
                <div className="buttons-container">
                  <button className="buttonEquate" style={{backgroundColor: trueBtnColor}}>
                    True
                  </button>
                  <div id="button-spacer" />
                  <button className="buttonEquate" style={{backgroundColor: falseBtnColor}}>
                    False
                  </button>
                </div>
              ) : (
                <div className="buttons-container">
                  <button
                    className="buttonEquate"
                    style={{backgroundColor: trueBtnColor}}
                    onClick={() => submitAnswer(AnswerChoices.true)}
                  >
                    True
                  </button>
                  <div id="button-spacer" />
                  <button
                    className="buttonEquate"
                    style={{backgroundColor: falseBtnColor}}
                    onClick={() => submitAnswer(AnswerChoices.false)}
                  >
                    False
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div>
              {assessmentPart ? (
                <div className="buttons-container">
                  <button
                    className="buttonEquate"
                    style={{backgroundColor: trueBtnColor}}
                    onClick={() => submitAnswer(AnswerChoices.true)}
                  >
                    True
                  </button>
                  <div id="button-spacer" />
                  <button
                    className="buttonEquate"
                    style={{backgroundColor: falseBtnColor}}
                    onClick={() => submitAnswer(AnswerChoices.false)}
                  >
                    False
                  </button>
                </div>
              ) : (
                <div className="buttons-container">
                  <button className="buttonEquate" style={{backgroundColor: trueBtnColor}}>
                    True
                  </button>
                  <div id="button-spacer" />
                  <button className="buttonEquate" style={{backgroundColor: falseBtnColor}}>
                    False
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

// const SessionPageInnerMemoized = React.memo(SessionPageInner);

export default SessionPage;
