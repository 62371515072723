import { useContext, useEffect, useState } from 'react';
import {IsSignedInContext} from '../../Components/AuthHandler';
import {auth} from '../../firebase';
import MustLogIn from '../../Components/MustLogIn';
import { useModel } from './hooks';
import Loading from '../../Components/Loading';
import GameInstructions from './instructions';
import { sleep } from '../../core/utils';
import RequireOpenBCI, { OpenBCIContext } from '../../Components/RequireOpenBCI';
import { useWordAssociations } from '../Baseline/hooks';
const tf = require('@tensorflow/tfjs');

const GamePage = () => {
    const isSignedIn = useContext(IsSignedInContext);
    if (!isSignedIn) {
        return <MustLogIn />
    }
    return (
    <RequireOpenBCI>
      <GamePageHandler />
    </RequireOpenBCI>
    );
}

const GamePageHandler = (props) => {
    const N_ASSOS_REQUIRED = 1;

    const model = useModel('https://storage.googleapis.com/tfjs-models/savedmodel/mobilenet_v2_1.0_224/model.json', onModelLoaded);
    const [modelIsLoaded, setModelIsLoaded] = useState(true);
    const [wordsLoaded, setWordsLoaded] = useState(false);
    const [showInstructions, setShowInstructions] = useState(true);

    const wordAssos = useWordAssociations(N_ASSOS_REQUIRED, onWordAssosLoaded);
    const openbci = useContext(OpenBCIContext);

    // const model = null;

    async function onWordAssosLoaded() {
        setWordsLoaded(true);
        await sleep(1); // allow openbci to get to consistent sampling rate
        // await sleep(10000);
        setShowInstructions(false);
    }

    async function onModelLoaded() {
        // make preliminary prediction to force model to be loaded
        const zeros = tf.zeros([1, 224, 224, 3]);
        await model.predict(zeros);
        setModelIsLoaded(true);
    }

    if (showInstructions) {
        return <GameInstructions />
    }
    if (!modelIsLoaded || !wordsLoaded) {
        return <Loading text="Getting baseline trial ready..." size={200}/>
    }
    
    return <GamePageLoaded
        model={model}
        wordAssos={wordAssos}
        />
}

const GamePageLoaded = (props) => {
    const model = props.model;
    const wordAssos = props.wordAssos;
    const openbci = useContext(OpenBCIContext);

    const [prediction, setPrediction] = useState(null);

    function predict() {
        const startTime = performance.now();
        const zeros = tf.zeros([1, 224, 224, 3]);
        const result = model.predict(zeros);
        setPrediction(result);
        console.log('Predicted in', performance.now() - startTime);
    }

    return <div>
        <button onClick={predict}>Predict</button>
        {prediction?.toString()}
    </div>
}


export default GamePage;