import './styles.css';
import Header from '../../Components/Header';

export default function Error404() {
    return (
        <div className="error-page">
            <Header />
            <div className="error">
                <h1>404</h1>
                Page not found
            </div>
        </div>
    );
}