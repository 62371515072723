import {useState, useRef, useEffect} from 'react';
import {useDetectClickOutside, useWindowDimensions} from './hooks';
import LinkedInLogo from '../../Components/Logos/linkedin_logo';

const MemberCard = (props) => {
    const name = props.name;
    const projectPosition = props.position;
    const src = props.src;
    const blurb = props.blurb;
    const linkedin = props.linkedin;

    const [isExpanded, setIsExpanded] = useState(false);
    const [expandedLocation, setExpandedLocation] = useState(null);
    const {windowWidth} = useWindowDimensions();
    const expandedRef = useRef(null);
    const compRef = useRef(null);
    const memberRef = useRef(null);
    useDetectClickOutside(compRef, onClickOutside);

    function calculatePosition(e) {
        // try to center below selected item but don't go past edges of screen
        const popupStyle = window.getComputedStyle(expandedRef.current);
        const popupMarginLeft = Number(popupStyle.getPropertyValue('margin-left').match(/\d+/));
        const popupMarginRight = Number(popupStyle.getPropertyValue('margin-right').match(/\d+/));
        const popupWidth = expandedRef.current.offsetWidth + popupMarginLeft + popupMarginRight;
        var rect = memberRef.current.getBoundingClientRect();
        const center = (rect.left + rect.right) / 2;
        let popupLeft = (center - (popupWidth / 2));
        popupLeft = Math.max(popupLeft, 0); // prevent off screen left
        popupLeft = Math.min(popupLeft, windowWidth - popupWidth); // prevent off screen right
        return {
            top: rect.bottom + window.pageYOffset + 'px',
            left: popupLeft + window.pageXOffset + 'px',
        }
    }

    function handleClick() {
        if (!isExpanded) {
            setIsExpanded(true);
            const position = calculatePosition();
            setExpandedLocation(position);
        } else {
            setIsExpanded(false);
        }
    }

    function onClickOutside() {
        setIsExpanded(false);
    }

    return (
        <div className={'team-member-card'} ref={compRef}>
            <div className={'team-member' + (isExpanded ? ' active' : '')} ref={memberRef} onClick={handleClick}>
                <img className='member-photo' src={src}/>
                <div className='member-name'>{name}</div>
                <div className='member-position'>{projectPosition}</div>
            </div>
            <div className={'member-info-expanded' + (isExpanded ? '' : ' hidden')} ref={expandedRef}
                 style={expandedLocation}>
                <div className='personal-info'>
                    <div>
                        <div className='member-name-expanded'>{name}</div>
                        <div className='member-position-expanded'>{projectPosition}</div>
                    </div>
                    <a className='linkedin-link' href={linkedin} target="_blank">
                        <LinkedInLogo className="linkedin-logo" size="16px"/>
                        LinkedIn
                    </a>
                </div>
                <div className='member-blurb'>
                    {blurb}
                </div>
            </div>
        </div>
    )
}

export default MemberCard;