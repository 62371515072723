import './styles.css';
// import Button from '@material-ui/core/Button';
import React from 'react';

function ButtonSponsor(props){
    return(
        <div className="div-for-sponsor-buttons">
        <button className="SponsorButton" variant="outlined" color="primary" onClick={props.onClick}>
            <a className="SponsorButton" href={props.link}>{props.text}<img className="home-sponsor-image" src={props.image}></img></a>
            
        </button>
        </div>
    )
}


export default ButtonSponsor;