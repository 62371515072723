import {Grid, Typography} from '@material-ui/core';
import React, { useState , useEffect, useContext} from 'react';
import './styles.css';
import { TrialTypes, AnswerChoices} from './structs';
import { useAtomicAnswerQueue, useWordAssociations, useTrialIndex } from './hooks';
import RequireOpenBCI, { OpenBCIContext } from '../../Components/RequireOpenBCI';
import { uploadDataAndTriggerVm } from '../../core/compute_firestore_integration';
import { sleep } from '../../core/utils';
import OddballInstructions from './instructions';
import { auth } from '../../firebase';
import { IsSignedInContext } from '../../Components/AuthHandler';
import MustLogIn from '../../Components/MustLogIn';

// Adjust these paramaters if needed
const SHOULD_UPLOAD = true;

const showColorTimer = 1000;
const allowClickTimer = 2000;

const interTrialDelayMin = 1000 // the lowest value for the inter trial delay in ms
const interTrialDelayMax = 1500 // the difference between the min and max inter trial delays

var currentAnswer = 0;
var localRecordings = []; //Used to store each trials brain data

const numTrials = 10; // tptal number of trials
var trialSchedule = [];
// generates a random ordering of true and false tests
console.log("Generating trial schedule...");
for (var i = 0; i < numTrials; i++){
  console.log(i + ": " + Math.random());
	if (Math.random() <= 0.8 || (i > 1 && trialSchedule[i-1] == TrialTypes.oddball && trialSchedule[i-2] == TrialTypes.oddball)){
		trialSchedule.push(TrialTypes.standard);
	}
	else{
		trialSchedule.push(TrialTypes.oddball);
	}
}

//Returns a time in ms of the inter trial delay using the minimum value and the variance between the min and max
function getInterTrialDelay(){
	return Math.floor(Math.random() * (interTrialDelayMax - interTrialDelayMin)) + interTrialDelayMin;
}


const OddballPage = () => {
    return (
    <RequireOpenBCI>
      <OddballPageInner />
    </RequireOpenBCI>
    );
}

const OddballPageInner = (props) => {
  const openbci = useContext(OpenBCIContext);
  const isSignedIn = useContext(IsSignedInContext);
  
  //const {nTrialsTotal, nAssosRequired} = getTrialInfo(trialSchedule);

  // UI State
  const [isFinished, setIsFinished] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [showColor, setShowColor] = useState(false);
  const [color, setColor] = useState(0);
  const [showInstructions, setShowInstructions] = useState(false);
  
  // Internal State
  const [trialIndex, startFirstTrial, startNextTrial] = useTrialIndex(numTrials, runTrial, onFinish);
  const [answers, submitAnswer] = useAtomicAnswerQueue(trialIndex, numTrials);
  //const [recordings, setRecordings] = useState([]); //originally used to store the recordings, had issues with it storing numTrials - 1 instead of numTrials

  const [startOddball, setStartOddball] = useState(true); 

  useEffect(() => {
    start();
  }, [startOddball]);

  useEffect(() => {
    window.addEventListener('blur', onBlur);
    return () => {
      window.removeEventListener('blur', onBlur);
    }
  }, []);

  function onBlur() {
    // TODO: reset baseline or send to a "timeout corner"
    console.log('blurred');
  }

  async function start(){

    console.log("Showing Instructions...")

    setShowInstructions(true);
    await sleep(10000); // allow openbci to get to consistent sampling rate
    setShowInstructions(false);

    console.log("Starting First Trial...")

    startFirstTrial();
  }

  //Starts the openbcin and records a single trial
  async function recordTrial() {
		console.log('Starting trial:', trialIndex, (new Date()).getTime());
    let array = ["Trial Number: " + trialIndex + ", Trial Type = " + trialSchedule[trialIndex]];
    let sampleTimes = [showColorTimer,allowClickTimer];
    await openbci.startTrial(sampleTimes, array);
    array[0] = "Trial Number: " + trialIndex + ", Trial Type = " + trialSchedule[trialIndex] + ", Label: " + currentAnswer;
    //setRecordings(prevRecordings => [...prevRecordings, array]);
    localRecordings.push(array);
    
    console.log(array);
  }
  
  //When finished, disconnects the openbci and uploads recordings to firestore
  async function onFinish() {
    await openbci.disconnect();
    console.log(localRecordings);
    setIsFinished(true);
    if (SHOULD_UPLOAD) {
      const uid = auth.currentUser.uid;
      uploadDataAndTriggerVm(uid, 'oddball/' + uid + '.raw', localRecordings);
    }
  }

  //Updates the label when the button is clicked
  useEffect(() => {
    console.log(answers);
    const answer = answers[answers.length-1];
    if (answers.length > 0){
      if (answer != AnswerChoices.timeout) {
        openbci.setTriggerByCode(1);
        console.log("User clicked button");
        currentAnswer = 1;
      }
    }
  }, [answers]);

  function runTrial() {
    //Initializes all variables and sets color visible and buttons invisible
    setShowButton(false);
    setShowColor(true);
    currentAnswer = 0;
    //Sets square to appropriate color
    if (trialSchedule[trialIndex] == TrialTypes.standard){
      setColor(0);
    }
    else{
      setColor(1);
    }
    recordTrial();

    //Sets visibilites and actions at set times
    setTimeout(() => {
      setShowButton(true);
    }, showColorTimer);
    setTimeout(() => {
      submitAnswer(AnswerChoices.timeout);
      setShowButton(false);
      setShowColor(false);
    }, showColorTimer + allowClickTimer);
    setTimeout(() => {
      startNextTrial();
    }, getInterTrialDelay() + showColorTimer + allowClickTimer);

  }

  if (!isSignedIn) {
    return <MustLogIn />
  }
  if (showInstructions) {
    return <OddballInstructions />
  }
  if (isFinished) {
    return <div>Done</div>
  }
  return (
    <div className="CategoriesPage">
      <div className="title-container">
        <div variant="h4" className="info-title">
          Oddball
        </div>
        <div className="infoParagraphEquate">
          Click the button if red.
        </div>
      </div>
      {showColor ?
      <div className="image-container">
        {color ?
          <div className="redSquare"></div>
        : 
          <div className="greenSquare"></div>
        }
      </div>
      : null}
      	{showButton ?
          <div className="buttons-container">
            <button className="button" onClick={() => submitAnswer(AnswerChoices.clicked)}> Oddball! </button>
          </div>
          : null
          }
    </div>
  );
}

export default OddballPage
