import { from, fromEvent, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { epoch } from "@neurosity/pipes";
import { wavelet } from "../wavelet"
import mock_serial from './mock_serial';
import ChunkProcessor from './ChunkProcessor';

const BAUD_RATE = 115200;
const START_CODE = 98;
const END_CODE = 115;
const DEFAULT_TRIGGER = 0;
const srate = 0.125
const interval = 100 //time in between epochs (typically 6000)

//https://www.npmjs.com/package/@neurosity/pipes
//https://neurosity.github.io/eeg-pipes/

export default class OpenBCIClient {

	constructor({useMock=false, debug=true} = {}){
		this.eegReadings = new Subject();
		this.useMock = useMock;
		this.isConnected = false;
		this.isRecording = false;
		this.segments = {0:0}
		this.debug = debug
		this.chunkProcessor = new ChunkProcessor();
	}

	isMock() {
		return this.useMock;
	}

	_timeToSamples(time) {
		return Math.floor(time * srate);
	}

	getProcessedSamples(segments) {
		var segmentTotal = 0
		for (var i = 0; i < segments.length; i++){
			segmentTotal += segments[i]
			var segmentIndex = i+1
			this.segments[segmentIndex] = this._timeToSamples(segmentTotal);
		}
		var currentSegments = this.segments;
		console.log("CURRENT SEGMENTS: " + JSON.stringify(this.segments));
		var currentDuration = this._timeToSamples(segmentTotal)
		return this.getSamples().pipe(epoch({ duration: currentDuration, interval: interval, samplingRate: srate }), wavelet({triggers:currentSegments}));
	}

	getSamples() {
		return new Observable(subscriber => {
			this.eegReadings.subscribe((value) => {
				var processedChunk = this.chunkProcessor.processChunk(value);
				for (var i = 0; i < processedChunk.length; i++) {
					var output = {};

					var sample = processedChunk[i];
					var data = [];
					for (var j = 0; j < sample.length; j++){
						data.push(sample[j]);
					}
					output.data = data;
					
					const d = new Date();
					output.timestamp = d.getTime();

					var info = {};
					info.samplingRate = 250;
					info.channelNames = ["FP1", "FP2", "C3", "C4", "P7", "P8", "O1", "O2", "F7", "F8", "F3", "F4", "T7", "T8", "P3", "P4"];

					output.info = info;

					subscriber.next(output);
				}
			})
		});
	}

	async _setupObservable() {
		  try {
		    while (true) {
		      const { value, done } = await this.reader.read();
		      if (done) {
		      	if (this.debug) {console.log("DEBUG: observable done")};
		        break;
		      }
		      this.eegReadings.next(value)
		    }
		  } catch (error) {
		    console.log("ERROR: " + error)
		  } finally {
		  	if (this.debug) {console.log("DEBUG: releasing lock")}
		    this.reader.releaseLock();
		  }
	}

	async connect(){
		this.port = await this._getSerialPort();
		await this.port.open({ baudRate: BAUD_RATE });
    	this.isConnected = true;
    	this.reader = this.port.readable.getReader();
    	this._setupObservable()
    	this.isConnected = true;
	}

	/**
	* Start recording. Must call setup first.
	*/
	async start() {
		this.isRecording = true;
		await this._writeToPort(START_CODE);
	}

	/**
	* Disconnect the OpenBCI and free all resources. This will allow the headset
	* to be used by other programs/components.
	* 
	* Note: after disconnecting, you must call setup() again if you want
	*       to record more data
	*/
	async disconnect() {
		if (this.debug) {console.log("DEBUG: openbci starting disconnect")};
		this.isConnected = false;
		await this._cleanup();
	}

	/**
	* Connects to OpenBCI via serial port. If using mock data it will return
	* the mocked serial port that will emit fake data.
	*/
	async _getSerialPort() {
		if (this.useMock) {
		  return mock_serial.requestPort(); // mocked serial port
		}
		else {
		  return navigator.serial.requestPort(); // actual serial port used if no mock
		}
	}

	/**
	* Write a Uint8 number to the serial port
	* @param {Number} intToWrite 
	*/
	async _writeToPort(intToWrite) {
		const writer = this.port.writable.getWriter();
		try {
		  const streamTrigger = new Uint8Array([intToWrite]);
		  await writer.write(streamTrigger);
		}
		catch(err) {
		  console.error(err);
		}
		finally {
		  writer.releaseLock();
		}
	}

	/**
	* Release resources after recording is finished. Allow OpenBCI to be used elsewhere.
	*/
	async _cleanup() {
		this.reader.cancel();
		this.reader.releaseLock();
		await this._writeToPort(END_CODE);
		await this.port.close();
	}
}

