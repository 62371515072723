import { uploadDataAndTriggerVm } from '../../core/compute_firestore_integration';
import { sleep } from '../../core/utils';
import './style.css';
import RequireOpenBCI, { OpenBCIContext } from '../../Components/RequireOpenBCI';

async function baselineTestBySamples(recorder, shouldUpload = false) {
    recorder.startBaseline();

    // await sleep(10000);

    const showWordTimer = 200; //ms until word is shown
    const showButtonTimer = 1000; //ms until buttons are shown
    const showAnswerTimer = 1000; //ms until answer is shown
    const allowClickTimer = 1000;
    let array = [];
    let sampleTimes = [showWordTimer,showButtonTimer,showAnswerTimer,allowClickTimer];
    await recorder.startTrialTimed(sampleTimes, array);

    recorder.stopRecording();
    console.log(array);
    if (shouldUpload) {
        uploadDataAndTriggerVm('test_user_uid', 'baseline/test_data.raw', array);
    }
}

const TestPage = () => {
    return (
    <RequireOpenBCI>
        <OpenBCIContext.Consumer>
        {openbci =>
            <div>
                <div className="sm-padding">
                    <button className="Button" onClick={() => baselineTestBySamples(openbci)}>
                        Record
                    </button>
                </div>
                {/* <div className="sm-padding">
                    <button className="Button" onClick={() => baselineTestBySamples(openbci, true)}>
                        Record + upload
                    </button>
                </div> */}
            </div>
        }
        </OpenBCIContext.Consumer>
    </RequireOpenBCI>
    );
}

export default TestPage;