import {Grid} from "@material-ui/core";
import {useState} from "react";

const LeaderboardCards = (props) =>{
    const [showDetails, setShowDetails] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const breakpoint = 450;

    const user_name = props.user_name;
    const profile_pic = props.profile_pic;

    const user_stat = [
        ['Average Score', props.avg_score],
        ['Koins', props.koins],
        ['Words Learnt', props.words_learnt],
        ['Accuracy', props.accuracy + "%"]
    ]

    let general_stat = [];
    let main_stat = [];

    for(let i = 0; i<user_stat.length; i++){
        if(user_stat[i][0] === props.sorted_by){
            main_stat = user_stat[i];
        }else{
            general_stat.push(
                {
                    stat_item: user_stat[i][0],
                    stat_data: user_stat[i][1]
                }
            );
        }
    }

    console.log(general_stat)
    let rank = props.rank;

    if(rank<10){
        rank = ('0' + rank).slice(-2)
    }

    window.addEventListener("resize", () => setWindowWidth(window.innerWidth));

    function handleShowDetails(e){
        console.log("success");
        if(showDetails === true){
            setShowDetails(false);
        }else{
            setShowDetails(true);
        }
        e.preventDefault();
    }

    return(
        <div className="cards">
            <div className="profile-card">
                <Grid container className="cards-content" justify="space-between">
                    <Grid item>
                        <Grid container>
                            <Grid item>
                                <div className="rankingNum">{rank}</div>
                            </Grid>
                            <Grid item>
                                <img className="profile-pic" src={profile_pic} alt="profile-pic"/>
                            </Grid>
                            <Grid item>
                                <div className="profile-name">{user_name}</div>
                                <div className="achievement/minutes">{main_stat[0]}: {main_stat[1]}</div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid item className="viewDetails">
                            <a className="viewDetailsButton" onClick={handleShowDetails}>View</a>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            {showDetails || windowWidth < breakpoint?(
                <div className="stats-card">
                    <Grid container className="detail-stats" spacing={2}>
                        {general_stat.map(stat => (
                            <Grid item>{stat.stat_item} : {stat.stat_data}</Grid>
                        ))}
                    </Grid>
                </div>
            ):null}
        </div>
    )
}

export default LeaderboardCards;