import './styles.css';
import Header from "../Header";
import Footer from "../Footer";
// import numberCounter from "../../Components/numberCounter";  / some debugging needed here
import {Grid} from "@material-ui/core";

function InnerIndex(){

    return(
        <div className={"tinyBody"}>
            {/*check if web serial is working on said browser/device and show relevant message*/}
            {"serial" in navigator
            ?
                <div>

                <p className = {"verify"}>
                    <span className="dot" id={"true"}>
                        <span className={"Check"}>&#10003;</span>
                    </span>
                    You're off to a great start! Your browser supports the Web Serial API :)
                </p>



                </div>
            :
                <div>
                    <p className={"verify"} >
                    <span className="dot" id={"false"}>
                        <span className={"Check"}>&#9747;</span>
                    </span>
                        Oh no, the web serial API is not supported :(. Maybe try a different device or chrome
                    </p>
                </div>

            }




        <Header/>
        <div>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: '100vh' }}
            >
                <p className={"headerTitle"}> How to enable google chrome Flags</p>


                <Grid className={"numberCard"} xs={"5"} >
                    <div>
                    <Grid container justify = "center">
                        <span className={"dot numberDot"}>
                        <div className={"numbers"}>1</div>
                    </span>
                    </Grid>

                        <p>
                            Firstly, Ensure you're in a chrome browser type chrome://flags in the address bar and hit enter.
                        </p>
                        {/*<img className={"tutorial-pics"} src={"flagTutorial/searchbar.png"} alt={"Typing chrome flag into searchbar"}/>*/}
                    </div>
                </Grid>

                <Grid className={"numberCard"} xs={"5"} >
                    <Grid container justify = "center">
                        <span className={"dot numberDot"}>
                        <div className={"numbers"}>2</div>
                    </span>
                    </Grid>
                    <p>
                        You will now be on a page titled “Experimental.” It’s divided into two columns: Available and Unavailable. You can scroll through the “Available” list or search for a specific flag.
                    </p>
                    {/*<img className={"tutorial-pics"} src={"flagTutorial/searching.png"} alt={"Typing chrome flag into searchbar"}/>*/}
                </Grid>


                <Grid className={"numberCard"} xs={"5"} >
                    <Grid container justify = "center">
                        <span className={"dot numberDot"}>
                        <div className={"numbers"}>3</div>
                    </span>
                    </Grid>
                    <p>
                        When you find a flag to turn on, select the drop-down menu (It will usually say “Default.”) and choose “Enabled.”
                    </p>
                    {/*<img className={"tutorial-pics"} src={"flagTutorial/dropdown.png"} alt={"Typing chrome flag into searchbar"}/>*/}

                </Grid>


                <Grid className={"numberCard"} xs={"5"} >
                    <Grid container justify = "center">
                        <span className={"dot numberDot"}>
                        <div className={"numbers"}>4</div>
                    </span>
                    </Grid>
                    <p>
                        After enabling a flag, you’ll need to restart the browser to apply the change. You can enable multiple flags before relaunching. Select the blue “Relaunch” button when you’re ready.
                    </p>
                    {/*<img className={"tutorial-pics"} src={"flagTutorial/relaunch.png"} alt={"Typing chrome flag into searchbar"}/>*/}

                </Grid>
            </Grid>












        </div>
        <Footer/>
        </div>
        
        )

}


export default InnerIndex;