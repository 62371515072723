import React from "react";
import { Grid, Card, CardContent, Typography, CardHeader, GridList } from '@material-ui/core';

export default class Formatting extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        isDesktop: false //default assume not a desktop
      };
  
      this.updatePredicate = this.updatePredicate.bind(this);

    }
    componentDidMount()
    {
      this.updatePredicate();
      window.addEventListener("resize", this.updatePredicate);
    }
  
    componentWillUnmount()
    {
      window.removeEventListener("resize", this.updatePredicate);
    }
  
    updatePredicate()
    {
      this.setState({isDesktop: window.innerWidth < 1450});
    }
    render() {
        // const {classes} = this.props;
        const isDesktop = this.state.isDesktop;
        if (isDesktop) {
            return (
                <div>
                <Grid item sm={2}>             
                </Grid>
                {/* <img src="Koalacademy_2000.png" className="logoLarge" alt="Koalacademy logo"></img> */}
                </div>
            );
        }
        return(
            <div></div>
        );
    }
}