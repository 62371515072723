import {Grid, Typography} from '@material-ui/core';
import React, { useState , useEffect, useContext} from 'react';
import './styles.css';
import Loading from '../../Components/Loading';
import { TrialTypes, AnswerChoices, isCorrect } from './structs';
import { useAtomicAnswerQueue, useWordAssociations, useTrialIndex } from './hooks';
import RequireOpenBCI, { OpenBCIContext } from '../../Components/RequireOpenBCI';
import { uploadDataAndTriggerVm } from '../../core/compute_firestore_integration';
import { sleep } from '../../core/utils';
import BaselineInstructions from './instructions';
import { auth } from '../../firebase';
import { IsSignedInContext } from '../../Components/AuthHandler';
import MustLogIn from '../../Components/MustLogIn';
import { loadRandomWordAssos, getTimingInfo } from './database';
import WebSerialCheck from "../../Components/WebSerialCheck";
import { getPreprocessingParam } from './database'

// Adjust these paramaters if needed
const SHOULD_UPLOAD = true;

var param_doc_id;

var showWordTimer;//ms until word is shown
var showButtonTimer;//ms until buttons are shown
var showAnswerTimer;//ms until answer is shown
var allowClickTimer;//ms until the user cannot respond

var trialLength;// ms
var interTrialDelayMin;// the lowest value for the inter trial delay in ms
var interTrialDelayVariance;// the difference between the min and max inter trial delays

var partOneTriggerCodes = [1001,2001,3001,4001,5001,6001];
var partTwoTriggerCodes = [1001,2001,3001,3002,5001,5002,5003,5004,6001];

var numBlocks;
var blockIndex = 0;
var numTrials;
var trialIndex = 0;
var falseTrialCount = 0;

var isPartOne = true;

var partTwoSchedule = [];
var partTwoAssociations = {};

var recordings = [];
var sampleCount = 0;
var triggers = [];
var labels = [];

/* Randomize array in-place using Durstenfeld shuffle algorithm */
function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
}

// generates a random ordering of true and false tests
function generatePartTwoSchedule(){
	partTwoSchedule = [];
	//Fill array with indices from 0 to numTrials-1
	for (var i = 0; i < numTrials; i++){
		partTwoSchedule.push(i);
	}
	//Shuffle the array
	shuffleArray(partTwoSchedule);
	//Select the first 5 to be true associations, last 5 to be false associations
	for (var i = 0; i < numTrials; i++){
		if (i < numTrials/2){
			partTwoAssociations[i] = TrialTypes.goodMatch;
		}
		else{
			partTwoAssociations[i] = TrialTypes.badMatch;
		}
	}
	//Shuffle the array again
	shuffleArray(partTwoSchedule);
}

function initializeLabels(){
	for (var i = 0; i < numBlocks; i++){
		labels.push([]);
	}
}

function getInterTrialDelay(){
	return Math.floor(Math.random() * interTrialDelayVariance) + interTrialDelayMin;
}


const BaselinePage = () => {
	const [isLoading, setIsLoading] = useState(true);

	async function loadTimingInfo(){
		if (isLoading){
			console.log("Debug: Loading timing info.")
			var docData = await getTimingInfo();

	    param_doc_id = docData["id"];

	    var trialTimes = docData["trial_times"];
	    showWordTimer = trialTimes[0];
	    showButtonTimer = trialTimes[1];
	    showAnswerTimer = trialTimes[2];
	    allowClickTimer = trialTimes[3];

	    trialLength = showWordTimer + showButtonTimer + showAnswerTimer + allowClickTimer;
	    
	    interTrialDelayMin = docData["iti_low"];
	    interTrialDelayVariance = docData["iti_high"] - interTrialDelayMin;

	    numTrials = docData["num_trials"];
      console.log("numTrials: " + numTrials)
	    numBlocks = docData["num_blocks"];
      console.log("numBlocks: " + numBlocks);

	    initializeLabels();

	    setIsLoading(false);
		}
	}

	loadTimingInfo()

	if (isLoading) {
	    return <Loading text="Loading baseline information..." size={200}/>
	}
    return (
    <RequireOpenBCI>
      <BaselinePageInner />
    </RequireOpenBCI>
    );
}

const BaselinePageInner = (props) => {
  const isMock = useContext(OpenBCIContext).isMock;
  const openbci = useContext(OpenBCIContext).device; 
  const isSignedIn = useContext(IsSignedInContext); 

  const nTrialsTotal = numTrials*numBlocks;
  const nAssosRequired = numTrials*numBlocks + 0.5*numTrials*numBlocks;

  // UI State
  const [engWord, setEngWord] = useState(""); 
  const [chnChar, setChnChar] = useState("");
  const [trueBtnColor, setTrueBtnColor] = useState();
  const [falseBtnColor, setFalseBtnColor] = useState(); 
  const [showAnswer, setShowAnswer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isFinished, setIsFinished] = useState(false);
  const [showButtons, setShowButtons] = useState(false); 
  const [showWords, setShowWords] = useState(false); 
  const [showInstructions, setShowInstructions] = useState(false);
  
  // Internal State
  const wordAssos = useWordAssociations(nAssosRequired, onWordAssosLoaded);
  const [isIntermission, setIsIntermission] = useState(false);
  const [internalTrialIndex, setInternalTrialIndex] = useState(0);
  const [internalBlockIndex, setInternalBlockIndex] = useState(0);
  const [internalIsPartOne, setInternalIsPartOne] = useState(true);

  useEffect(() => {
    window.addEventListener('blur', onBlur);
    return () => {
      window.removeEventListener('blur', onBlur);
    }
  }, []);

  function onBlur() {
    // TODO: reset baseline or send to a "timeout corner"
    console.log('blurred');
  }

  async function submitAnswer(answer){
  	console.log("Debug: Submitting answer: " + answer);
  	if (isPartOne){
  		setTrueBtnColor('green');
      setFalseBtnColor('white');
      if (answer != AnswerChoices.timeout){
  			triggers.push([partOneTriggerCodes[4], sampleCount]);
  		}
  	}
  	else{
  		if (labels[blockIndex][trialIndex] != undefined){
  			//if an answer is already submitted
  			return;
  		}
  		if (partTwoSchedule[trialIndex] == TrialTypes.goodMatch){
  			setTrueBtnColor('green');
        setFalseBtnColor('white');
  			if (answer == AnswerChoices.true){
  				labels[blockIndex].push(1);
  				triggers.push([partTwoTriggerCodes[4], sampleCount]);
  			}
  			else if (answer == AnswerChoices.false){
  				labels[blockIndex].push(2);
  				triggers.push([partTwoTriggerCodes[5], sampleCount]);
  			}
  			else{
  				labels[blockIndex].push(0);
  			}
  		}
  		else{
  			setTrueBtnColor('white');
        setFalseBtnColor('green');
  			if (answer == AnswerChoices.false){
  				labels[blockIndex].push(3);
  				triggers.push([partOneTriggerCodes[6], sampleCount]);
  			}
  			else if (answer == AnswerChoices.true){
  				labels[blockIndex].push(4);
  				triggers.push([partOneTriggerCodes[7], sampleCount]);
  			}
  			else{
  				labels[blockIndex].push(0);
  			}
  		}
  	}
  }

  async function resetButtonColors(){
  	setTrueBtnColor('white');
    setFalseBtnColor('white');
  }

 	async function onWordAssosLoaded() {
 		console.log("Debug: Word associations loaded, showing instructions.")
	  // start first trial once word associations have loaded
	  setIsLoading(false);

	  setShowInstructions(true);
	  await sleep(10000); // allow openbci to get to consistent sampling rate
	  setShowInstructions(false);

	  startPartOne();
  }

  async function startPartOne(){
  	console.log("Debug: Starting part one for blockIndex: " + blockIndex);
  	isPartOne = true;
  	setInternalIsPartOne(true);
  	//if first block then start recording
  	if (blockIndex == 0){
  		startRecording();
  	}
  	//set trialIndex to 0
  	trialIndex = 0;
  	setInternalTrialIndex(0);
  	falseTrialCount = 0;
  	//run trial
  	runPartOneTrial();
  }

  async function runPartOneTrial(){
  	console.log("Debug: Running part one for trialIndex: " + trialIndex);
  	setShowButtons(false);
    setShowWords(false);
    setShowAnswer(false);

    var wordIndex = trialIndex + numTrials*blockIndex;
  	setChnChar(wordAssos[wordIndex].chinese_char);
  	setEngWord(wordAssos[wordIndex].eng_word);

  	var delay = getInterTrialDelay();

    triggers.push([partOneTriggerCodes[0], sampleCount]);
    setTimeout(() => {
      setShowWords(true);
      triggers.push([partOneTriggerCodes[1], sampleCount]);
    }, showWordTimer);
    setTimeout(() => {
      setShowButtons(true);
      triggers.push([partOneTriggerCodes[2], sampleCount]);
    }, showWordTimer+showButtonTimer);
    setTimeout(() => {
      setShowAnswer(true);
      triggers.push([partOneTriggerCodes[3], sampleCount]);
    }, showWordTimer+showButtonTimer+showAnswerTimer);
    setTimeout(() => {
      submitAnswer(AnswerChoices.timeout);
    }, trialLength);
    setTimeout(() => {
    	resetButtonColors();
      triggers.push([partOneTriggerCodes[5], sampleCount]);
    	trialIndex++;
    	setInternalTrialIndex(internalTrialIndex + 1);
    	if (trialIndex >= numTrials){
    		startPartTwo();
    	}
    	else{
    		runPartOneTrial();
    	}
    }, trialLength + delay);
  }

  async function startPartTwo(){
  	console.log("Debug: Starting part two for blockIndex: " + blockIndex);
  	trialIndex = 0;
  	setInternalTrialIndex(0);

  	isPartOne = false;
  	setInternalIsPartOne(false);

  	generatePartTwoSchedule();
  	runPartTwoTrial();
  }

  async function runPartTwoTrial(){
  	console.log("Debug: Running part two for trialIndex: " + trialIndex);
  	setShowButtons(false);
    setShowWords(false);
    setShowAnswer(false);

    var wordIndex = trialIndex + numTrials*blockIndex

    var trialNum = partTwoSchedule[trialIndex];
    var trialType = partTwoAssociations[trialNum];
    if (trialType === TrialTypes.goodMatch){
  		setChnChar(wordAssos[wordIndex].chinese_char);
  		setEngWord(wordAssos[wordIndex].eng_word);
  	}
  	else if (trialType === TrialTypes.badMatch){
  		setChnChar(wordAssos[wordIndex].chinese_char);
  		setEngWord(wordAssos[wordAssos.length - falseTrialCount - 1].eng_word);
  		falseTrialCount++;
  	}

  	var delay = getInterTrialDelay();

    triggers.push([partTwoTriggerCodes[0], sampleCount]);
  	setTimeout(() => {
      setShowWords(true);
      triggers.push([partTwoTriggerCodes[1], sampleCount]);
    }, showWordTimer);
    setTimeout(() => {
      setShowButtons(true);
      triggers.push([partTwoTriggerCodes[2], sampleCount]);
    }, showWordTimer+showButtonTimer);
    setTimeout(() => {
      submitAnswer(AnswerChoices.timeout);
    }, trialLength);
    setTimeout(() => {
      resetButtonColors();
      triggers.push([partTwoTriggerCodes[8], sampleCount]);
    	trialIndex++;
    	setInternalTrialIndex(internalTrialIndex + 1);
    	if (trialIndex >= numTrials){
    		startIntermission();
    	}
    	else{
    		runPartTwoTrial();
    	}
    }, trialLength + delay);
  }

  async function startIntermission(){
  	console.log("Debug: Starting intermission for blockIndex: " + blockIndex);
  	blockIndex++;
  	setInternalBlockIndex(internalBlockIndex + 1);
  	if (blockIndex >= numBlocks){
  		finishAndUpload();
  	}
  	else{
  		setIsIntermission(true);
  	}
  }

  async function endIntermission(){
  	console.log("Debug: Ending intermission.")
  	setIsIntermission(false);
  	startPartOne();
  }

  async function startRecording() {

    console.log("Debug: Starting OpenBCI.");

    for (var i = 0; i < 17; i++){
      recordings.push([]);
    }

  	openbci.onmessage = function(message) {

      var response = message.data;
      var sample = response.data;

      for (var i = 0; i < sample.length; i++){
        recordings[i].push(sample[i]);
      }

      recordings[recordings.length-1].push(response.timestamp);

      sampleCount++;

  	}

    openbci.postMessage(1);

    console.log("Debug: OpenBCI started.");

  }

  function turnDataIntoCSV(data){
    var csvFile = '';
    //For each sample in the electrode recordings
    for (var i = 0; i < data[0].length; i++){
      // For each electorde in the sample + timestamp
      for (var j = 0; j < data.length; j++){
        if (j > 0){
          csvFile += ',' + data[j][i]
        }
        else{
          csvFile += data[j][i]
        }
        
      }
      // Find if any triggers occured at this sample
      var triggerFound = false
      for (var k = 0; k < data.length; k++) {
        if (data[k][1] == i){
          csvFile += ',' + data[k][0]
          triggerFound = true;
        }
      }
      if (!triggerFound){
        csvFile += ',' + 0
      }
      csvFile += '\n'
    }
    return csvFile
  }

  
  async function finishAndUpload() {
    console.log("Debug: Finished, disconnecting OpenBCI.");
    openbci.postMessage(0); // disconnect code for openbci
    openbci.terminate();
    setIsFinished(true);
    var csvFile = turnDataIntoCSV(recordings);
    if (SHOULD_UPLOAD) {
      const uid = auth.currentUser.uid;
      uploadDataAndTriggerVm(uid, 'baseline/' + uid + '_baseline_data.csv', csvFile, param_doc_id);
      uploadDataAndTriggerVm(uid, 'baseline/' + uid + '_baseline_labels.raw', JSON.stringify(labels), param_doc_id);
    }
  }

  if (!isSignedIn) {
    return <MustLogIn />
  }
  if (isLoading) {
    return <Loading text="Getting baseline trial ready..." size={200}/>
  }
  if (showInstructions) {
    return <BaselineInstructions />
  }
  if (isIntermission){
  	return <button onClick={() => endIntermission()}> Click to start next block </button>
  }
  if (isFinished) {
    return <div>Done</div>
  }
  return (
    <div className="CategoriesPage">
      <div className="title-container">
      {internalIsPartOne ?
        <div variant="h4" className="info-title">
          Part One
        </div>
      : 
        <div variant="h4" className="info-title">
          Part Two
        </div>
      }
        <div className="infoParagraphEquate">
          Are these the same?
        </div>
      </div>
      {showWords ?
      <div className="wordsContainer">
        <div className="word-card">
          {chnChar}
        </div>
        <div className="equalText">
          =
        </div>
        <div className="word-card">
          {engWord}
        </div>
      </div>
      : null}
      	{showButtons ?
          	<div>
	          	{showAnswer ?
	          	<div>
	            	{!internalIsPartOne ?
			            <div className="buttons-container">
				            <button className="buttonEquate" style = {{backgroundColor: trueBtnColor}} >
				              True
				            </button>
				            <div id="button-spacer" />
				            <button className="buttonEquate" style = {{backgroundColor: falseBtnColor}} >
				              False
				            </button>
			            </div>
			        :
			            <div className="buttons-container">
			            	<button className="buttonEquate" style = {{backgroundColor: trueBtnColor}} onClick={() => submitAnswer(AnswerChoices.true)}>
				              True
				            </button>
				            <div id="button-spacer" />
				            <button className="buttonEquate" style = {{backgroundColor: falseBtnColor}} onClick={() => submitAnswer(AnswerChoices.false)}>
				              False
				            </button>
			            </div>
		        	}
		        </div>
	            :
	            <div>
	            	{!internalIsPartOne  ?
			            <div className="buttons-container">
				            <button className="buttonEquate" style = {{backgroundColor: trueBtnColor}} onClick={() => submitAnswer(AnswerChoices.true)}>
				              True
				            </button>
				            <div id="button-spacer" />
				            <button className="buttonEquate" style = {{backgroundColor: falseBtnColor}} onClick={() => submitAnswer(AnswerChoices.false)}>
				              False
				            </button>
			            </div>
			        :
			            <div className="buttons-container">
				            <button className="buttonEquate" style = {{backgroundColor: trueBtnColor}} >
				              True
				            </button>
				            <div id="button-spacer" />
				            <button className="buttonEquate" style = {{backgroundColor: falseBtnColor}} >
				              False
				            </button>
			            </div>
		        	}
		        </div>
	        	}
	        </div>
          : null
          }
    </div>
  );
}

export default BaselinePage
