import ChunkProcessor from './ChunkProcessor';
import mock_serial from './mock_serial';

const BAUD_RATE = 115200;
const START_CODE = 98;
const END_CODE = 115;
const DEFAULT_TRIGGER = 0;

export default class OpenBciRecorder {
  /**
   * 
   * @param {Map} options
   */
  constructor({useMock=false} = {}) {
    this.useMock = useMock;
    this.trigger = 0;
    this.isConnected = false;
  }

  isMock() {
    return this.useMock;
  }

  /**
   * Connect to OpenBCI
   */
  async setup() {
    this.port = await this._getSerialPort();
    await this.port.open({ baudRate: BAUD_RATE });
    this.isConnected = true;
    this._recordForever(); // start openbci recording
  }

  /**
   * Disconnect the OpenBCI and free all resources. This will allow the headset
   * to be used by other programs/components.
   * 
   * Note: after disconnecting, you must call setup() again if you want
   *       to record more data
   */
  async disconnect() {
    console.log("OpenBCI starting disconnect");
    this.isConnected = false;
    await this._cleanup();
  }

  async setTriggerToAnswer(answerClicked, correctness){
    if (answerClicked == true && correctness == 0){
      //this.clickedTrigger = 4001;
      this.triggers[4001] = this.samplesRecorded;
    }
    else if (answerClicked == true && correctness == 1){
      //this.clickedTrigger = 4002;
      this.triggers[4002] = this.samplesRecorded;
    }
    else if (answerClicked == false && correctness == 0){
      //this.clickedTrigger = 4003;
      this.triggers[4003] = this.samplesRecorded;
    }
    else if (answerClicked == false && correctness == 1){
      //this.clickedTrigger = 4004;
      this.triggers[4004] = this.samplesRecorded;
    }
  }

  async setTriggerByCode(code){
    this.clickedTrigger = code;
  }

  async startTrial(segmentLengths, array) {
    if (!this.isRecording) {
      throw new Error('Must start recording before you can start a trial. e.g. call startBaseline()');
    }
    if (this.trialIsActive) {
      throw new Error('Cannot start new trial when a different trial is still active.');
    }
    if (array.length != 17){
      throw new Error('Array must contain 17 subarrays (16 electrodes + triggers)')
    }
    this.trialIsActive = true;
    this.trialArray = array;
    this.triggers = array[array.length-1];
    this.segmentTotals = [segmentLengths[0]];
    for (var i = 1; i < segmentLengths.length; i++){
      this.segmentTotals[i] = this.segmentTotals[i-1] + segmentLengths[i];
    }
    this.segmentIndex = 0;
    this.clickedTrigger = null;
    this.samplesRecorded = 0;
    this.maxSamples = this._timeToSamples(this.segmentTotals[this.segmentTotals.length-1]);

    while (this.samplesRecorded < this.maxSamples){
      await this.sleep(5);
    }

  }

  async sleep(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  }

  /**
   * Sets the trigger to the given trigger integer. The trigger will be added onto the
   * next chunk.
   * @param {Number} trigger 
   */
  sendTrigger(trigger) {
    this.trigger = trigger;
  }

  async _recordForever() {
    await this._start();

    while (this.isConnected) {
      // If a trial is currently active then write to the given trial array
      if (this.trialIsActive) {
        await this._saveSampleIntoArray(this.trialArray);
      }
      // If a trial is not active then chunks must still be processed
      else {
        await this._discardSample();
      }
    }
  }

  /**
   * Returns the current trigger and sets the global trigger to a constant DEFAULT_TRIGGER.
   * 
   * @return {integer} the trigger to be pushed alongside the current sample
   */
  _consumeTrigger() {
    const trigger = this.trigger;
    this.trigger = DEFAULT_TRIGGER;
    return trigger;
  }

  /**
   * Start recording. Must call setup first.
   */
  async _start() {
    this.isRecording = true;
    await this._writeToPort(START_CODE);
    this.reader = this.port.readable.getReader();
    this.chunkProcessor = new ChunkProcessor(); // will deal with buffer and combining 2x9 into 18
    this.data = []; // fresh data
  }

  async _discardSample() {
    const { value } = await this.reader.read();
    var _ = this.chunkProcessor.processChunk(value);
    return;
  }

  async _saveSampleIntoArray(array) {
    const { value } = await this.reader.read();
    var processedChunk = this.chunkProcessor.processChunk(value);
    for (var i = 0; i < processedChunk.length; i++) {
      if (this.samplesRecorded < this.maxSamples){
        for (var j = 0; j < processedChunk[i].length; j++){
          array[j].push(processedChunk[i][j]);
        }
        this.samplesRecorded++;
        if (this.samplesRecorded > this._timeToSamples(this.segmentTotals[this.segmentIndex])){
          this.segmentIndex++;
          this.triggers[this.segmentIndex] = this.samplesRecorded;
        }        
      }
      else {
        this._stopTrial();
        break;
      }
    }
  }

  _stopTrial() {
    this.trialIsActive = false;
    this.trialArray = null;
  }
  
  /**
   * 
   * @param {Number} time length of time in milliseconds
   */
  _timeToSamples(time) {
    return Math.floor(time * 0.125);
  }

  /**
   * Connects to OpenBCI via serial port. If using mock data it will return
   * the mocked serial port that will emit fake data.
   */
  async _getSerialPort() {
    if (this.useMock) {
      return mock_serial.requestPort(); // mocked serial port
    }
    else {
      return navigator.serial.requestPort(); // actual serial port used if no mock
    }
  }

  /**
   * Release resources after recording is finished. Allow OpenBCI to be used elsewhere.
   */
  async _cleanup() {
    this.reader.cancel();
    this.reader.releaseLock();
    console.log("Awaiting writeToPort");
    await this._writeToPort(END_CODE);
    console.log("Awaiting port.close");
    await this.port.close();
    console.log("Finished cleanup");
  }

  /**
   * Write a Uint8 number to the serial port
   * @param {Number} intToWrite 
   */
  async _writeToPort(intToWrite) {
    const writer = this.port.writable.getWriter();
    try {
      const streamTrigger = new Uint8Array([intToWrite]);
      await writer.write(streamTrigger);
    }
    catch(err) {
      console.error(err);
    }
    finally {
      writer.releaseLock();
    }
  }
}
