import './styles.css';
// import Button from '@material-ui/core/Button';
import React from 'react';

function ButtonLogIn(props){
    return(
        <div>
        <button className="LogInButton" variant="outlined" color="primary" onClick={props.onClick}>
            <a className="LogInButton" href={props.link}>{props.text}</a>
        </button>
        </div>
    )
}


export default ButtonLogIn;