import { useState } from 'react';
import SelectBrainDataSource from './select_source';
import ValidateOpenBCISetup from './validate_setup';
import OpenBciRecorder from '../../core/openbci/OpenBciRecorder';
import OpenBCIClient from '../../core/openbci/openbci';
// eslint-disable-next-line import/no-webpack-loader-syntax
import worker from 'workerize-loader!../../core/openbci/openbciWorker'; 
import React from 'react';
import './styles.css';
import WebSerialCheck from "../WebSerialCheck";
import Header from '../../Components/Header';
import { useLocation } from 'react-router';

const OpenBCIContext = React.createContext(null);
const DataSourceTypes = {
    'MOCK': 0,
    'OPEN_BCI': 1,
}

function createBrainDataSource(dataSource) {
    console.log("Debug: Creating brain data source.")
    var openbci = worker();
    switch (dataSource) {
        //CHANGE THIS WHOLE THING SO IT RETURNS BOTH THE OPENBCI AND WHETHER IT IS A MOCK DEVICE OR NOT
        case DataSourceTypes.MOCK:
            return {device:openbci, isMock:true};
        case DataSourceTypes.OPEN_BCI:
            return {device:openbci, isMock:false};
    }
    
}

const RequireOpenBCI = props => {
  const [dataSource, setDataSource] = useState(null);
  const [hadError, setHadError] = useState(null);

  function reset() {
    setDataSource(null);
    setHadError(true);
  }

  return dataSource != null ? (
    <OpenBCIContext.Provider value={createBrainDataSource(dataSource)}>
      <div className="openbciBody">
        <Header />
        <ValidateOpenBCISetup resetCallback={reset}>{props.children}</ValidateOpenBCISetup>
        {/*Check if the serial port works* and display relevant text*/}
        <WebSerialCheck/>
      </div>
    </OpenBCIContext.Provider>
  ) : (
    <div className="openbciBody">
      <Header />

      <SelectBrainDataSource hadError={hadError} setCallback={setDataSource} />
      {/*Check if the serial port works and display relevant text*/}
      <WebSerialCheck/>
    </div>
  );
};

export default RequireOpenBCI;
export { OpenBCIContext, DataSourceTypes };
