import { auth } from '../../firebase';
import { Grid } from '@material-ui/core';

function getProfileURL() {
    return auth.currentUser.photoURL
        ? auth.currentUser.photoURL
        : 'default_profile.png';
}

// async function updateName(newName, callback) {
//     await auth.currentUser.updateProfile({
//         displayName: newName,
//         photoURL: auth.currentUser.photoURL,
//     })
//         .then(() => {
//             callback();
//         })
//         .catch(

//         );
// }

export function UserInformation() {
    return (
        <Grid
            container
            direction="column"
            className="profilePageContainer"
        >
        <div className="profile-container">
          <div>
            <img className="profile-img-large" src={getProfileURL()} alt="Profile"/>
          </div>
          <div className="display-name">
            {auth.currentUser.displayName
              ? auth.currentUser.displayName
              : 'No Name'
            }
          </div>
          <div className='email-address'>
            {auth.currentUser.email}
          </div>
        </div>
        </Grid>
    );
}