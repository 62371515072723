import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyChVhriuU871L28R-Zxs1INjBxKaYixUjo",
  authDomain: "koalacademy.com",
  databaseURL: "https://koalacademy-f8862.firebaseio.com",
  projectId: "koalacademy-f8862",
  storageBucket: "koalacademy-f8862.appspot.com",
  messagingSenderId: "224003448591",
  appId: "1:224003448591:web:2c03e3a8e2a50363fb287a"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const firestore = firebase.firestore()
export const auth = firebase.auth();
export const storage = firebase.storage();
export default firebase;

// google authentication
const googleProvider = new firebase.auth.GoogleAuthProvider();
export const signInWithGoogle = () => {
  auth.signInWithPopup(googleProvider).then((res) => {
    console.log(res.user)
  }).catch((error) => {
    console.log(error.message)
  })
}

// set emulator sources if localhost
// if (window.location.hostname === "localhost") {
//   firestore.useEmulator("localhost", 8080);
//   auth.useEmulator("http://localhost:9099");
// }