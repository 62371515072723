import './App.css';
import {Switch, Route} from 'react-router-dom';
import {ROUTES} from '../constants';
import AuthHandler from '../Components/AuthHandler';

/**
 * All page imports
 */
import HomePage from '../Pages/Home';
import LogoutPage from '../Pages/Logout';
import OddballPage from '../Pages/Oddball';
import BaselinePage from '../Pages/Baseline';
import SessionPage from '../Pages/Session';
import ProfilePage from '../Pages/Profile';
import InfoPage from '../Pages/Info';
import TestPage from '../Pages/Test';
import TeamPage from '../Pages/Team';
import Error404 from '../Pages/Errors/error404';
import GamePage from '../Pages/Game';
import UnityPage from '../Pages/Unity';
import CommunityPage from '../Pages/Community';

function App() {
  return (
    <div className="App">
      <AuthHandler>
        <Switch>
          <Route exact path={ROUTES.HOME} component={HomePage} />
          <Route exact path={ROUTES.LOGOUT} component={LogoutPage} />
          <Route exact path={ROUTES.GAME} component={GamePage} />
          <Route exact path={ROUTES.UNITY} component={UnityPage} />
          <Route exact path={ROUTES.PROFILE} component={ProfilePage} />
          <Route exact path={ROUTES.INFO} component={InfoPage} />
          <Route exact path={ROUTES.TEST} component={TestPage} />
          <Route exact path={ROUTES.BASELINE} component={BaselinePage} />
          <Route exact path={ROUTES.SESSION} component={SessionPage} />
          <Route exact path={ROUTES.ODDBALL} component={OddballPage} />
          <Route exact path={ROUTES.TEAM} component={TeamPage} />
          <Route exact path={ROUTES.COMMUNITY} component={CommunityPage} />
          <Route
            component={Error404}
          />
          
        </Switch>
      </AuthHandler>
    </div>
  );
}

export default App;
