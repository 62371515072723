import { Grid } from "@material-ui/core";
import React, { Fragment, useEffect, useState, useContext } from "react";
import { auth, firestore } from '../../firebase';
import RequireOpenBCI, { OpenBCIContext } from '../../Components/RequireOpenBCI';
import Unity, { UnityContext } from "react-unity-webgl";
import Header from "../../Components/Header";
import { loadRandomWordAssos } from '../Baseline/database';
import { useAtomicAnswerQueue, useWordAssociations, useTrialIndex } from '../Baseline/hooks';
import "./styles.css";
import { sleep } from '../../core/utils';
// eslint-disable-next-line import/no-webpack-loader-syntax
import worker from 'workerize-loader!../../core/openbci/openbciWorker'; 
import OpenBCIClient from '../../core/openbci/openbci'

var word_asso_num;
var timing = [];
var triggers = [];
var trial_order = [];
var iti_low;
var iti_high;
var unityContext;
var wordAssos;

async function testWorker(){
  const openbci = new OpenBCIClient();
  const eegWorker = worker();
  eegWorker.addEventListener('message', (message) => {console.log(message);})
  //eegWorker.onmessage((message) => console.log(message.data));
  //await eegWorker.testFunction();
  //var port = await navigator.serial.requestPort();
  //await navigator.serial.requestPort();
  eegWorker.subscribeToRawSamples(true);
  await sleep(3000);
  eegWorker.postMessage(1);
  console.log("Connected to eegWorker");
  //await eegWorker.terminate();
  //console.log("Terminated eegWorker")
  /*let url = new URL("src/Pages/Unity/testWorker.js", window.location.origin);
  var myWorker = new Worker('/testWorker.js');
  myWorker.postMessage(['hi', 'bye']);
  console.log('Message posted to worker');*/
}

const UnityPage = () => {
    return (
      <UnityPageInner />
    );
} 

const UnityPageInner = (props) => {

  // STATE
  const [showUnity, setShowUnity] = useState(true);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [totalTimingArray, setTotalTimingArray] = useState([]);
  const [trialIndex, setTrialIndex] = useState(0);
  var timingArray = [];
  
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setIsSignedIn(true);
        window.scrollTo(0,0); // scroll to top after login
      }
      else {
        setIsSignedIn(false);
      }
    });

    return unsubscribe;
  }, [setIsSignedIn]);

  const openbci = useContext(OpenBCIContext);

  const unityContext = new UnityContext({
    codeUrl: "/build/Builds.wasm",
    frameworkUrl: "/build/Builds.framework.js",
    dataUrl: "/build/Builds.data",
    loaderUrl: "/build/Builds.loader.js",
  });
  unityContext.on("unityEvent", (code) => {
    //window.alert(code);
    var d = new Date();
    var n = d.getTime();

    if (code == 0){
      // If unity is done loading
      console.log("received code 0");
    }
    else if (JSON.stringify(code).charAt(0) == JSON.stringify(triggers[0]).charAt(0)){
      // If the trial has started in Unity
      if ((JSON.stringify(code).charAt(JSON.stringify(code).length) % 2) != trial_order[trialIndex]){
        throw new Error("Trial Type: " + code + "(" + ((JSON.stringify(code).charAt(JSON.stringify(code).length) % 2)) + ") does not match expected: " + trial_order[trialIndex]);
      }
      timingArray.push([0, n]);
      startOpenBCI(openbci, code, trialIndex);
    }
    else if (code == triggers[1]){
      // If the words have appeared
      timingArray.push([(n - timingArray[0][1]), n]);
    }
    else if (code == triggers[2]){
      // If the buttons have appeared
      timingArray.push([(n - timingArray[1][1]), n]);
    }
    else if (JSON.stringify(code).charAt(0) == JSON.stringify(triggers[3]).charAt(0)){
      // If the user clicks an answer
      openbci.setTriggerByCode(code);
      //timingArray.push([(n - timingArray[2][1]), n]);
      //setTotalTimingArray(prevArray => [...prevArray, timingArray]);
      //console.log("Unity finished trial " + trialIndex + " with timings: " + JSON.stringify(timingArray));
      //timingArray = [];
      //setTrialIndex(trialIndex + 1);
    }
    else if (code == triggers[4]){
      // If the trial has ended
      timingArray.push([(n - timingArray[2][1]), n]);
      setTotalTimingArray(prevArray => [...prevArray, timingArray]);
      console.log("Unity finished trial " + trialIndex + " with timings: " + JSON.stringify(timingArray));
      //setTimingArray([]);
      setTrialIndex(trialIndex + 1);
    }
    else{
      // If an invalid code has been returned
      throw new Error("Invalid code returned from Unity: " + code);
    }
  });

  ReadFromFirestore();

  /*async function send(message, messageTF) {
    var intoUnity = '[{"difficulty":"1","pk":16,"chinese_char":"睡觉","pinying":"Shuìjiào","eng_word":"sleep"},{"eng_word":"computer","pk":18,"difficulty":"1","pinying":"Diànnǎo","chinese_char":"电脑"},{"difficulty":"1","eng_word":"moring","pinying":"Zǎoshang","pk":10,"chinese_char":"早上"},{"pk":1,"eng_word":"mother","pinying":"Māmā","chinese_char":"吗吗","difficulty":"1"},{"eng_word":"hungry","pinying":"è","chinese_char":"饿","pk":15,"difficulty":"1"},{"pk":14,"difficulty":"1","chinese_char":"热","eng_word":"hot","pinying":"Rè"}]';
      intoUnity = intoUnity.split('"').join(''); //get rid of "", this can be unity side but this side saves time and it is also easier. 
    console.log("intoUnity from send(): " + JSON.stringify(intoUnity));
    await new Promise(resolve => setTimeout(resolve, 10000)); // this time needs to be long enough or unity doesn't load fast enough to recieve the data
      unityContext.send('Dictionary', 'Load', intoUnity); //unitycontext.send send to the object Dictionary's Load function with the message intoUnity. If you are unsure what to put in, just make sure the third argument is correct for now. 
    var intoUnityTFValue = '[0, 0, 1, 1]';
    await new Promise(resolve => setTimeout(resolve, 50));  //this wait time matters less for now. 
    unityContext.send('Dictionary', 'LoadTF', intoUnityTFValue);
    var intoUnityTimeValue = '[200, 1000, 100000]'; //first number : from nothing to word appearing. second number: from showing the words and then button. third number: time user answer
    await new Promise(resolve => setTimeout(resolve, 50));  //this wait time matters less for now. 
    unityContext.send('Dictionary', 'LoadTime', intoUnityTimeValue);
  }
  send();*/
  
  function getTrialInfo(trialSchedule) {
    const nTrialsTotal = trialSchedule.length;
    var nFalseTrials = 0;
    for (var i = 0; i < nTrialsTotal; i++){
        if (trialSchedule[i] == 0){
            nFalseTrials++;
        }
    }
    const nAssosRequired = nTrialsTotal + nFalseTrials;
    return {
        nTrialsTotal: nTrialsTotal,
        nAssosRequired: nAssosRequired
    }
  }

  async function ReadFromFirestore(){

    var timingRef = firestore.collection("admin").doc("timing").collection("timing (subcollection)");
    var querySnapshot = await timingRef.where("pk","==",1).get();

    if (querySnapshot.docs.length == 1){

      var doc = querySnapshot.docs[0];
      var docData = doc.data();
      console.log("docData: " + JSON.stringify(docData));
      timing = docData["trial_times"];
      triggers = docData["triggers"];
      trial_order = docData["trial_order"];
      iti_low = docData["iti_low"];
      iti_high = docData["iti_high"];

      loadIntoUnity();

    }
    else{

      throw new Error("Expected just 1 documented from admin.timing.timing(subcollection), received: " + querySnapshot.docs.length);

    }
  }

  async function loadIntoUnity(){
    // sent trial information into unity
    await new Promise(resolve => setTimeout(resolve, 5000));
    console.log("Loading timing into Unity: " + JSON.stringify(timing));
    await new Promise(resolve => setTimeout(resolve, 50));
    unityContext.send('Dictionary', 'LoadTime', JSON.stringify(timing));
    
    console.log("Loading trial order into Unity: " + JSON.stringify(trial_order));
    await new Promise(resolve => setTimeout(resolve, 50));
    unityContext.send('Dictionary', 'LoadTF', JSON.stringify(trial_order));

    await new Promise(resolve => setTimeout(resolve, 50));
    const {nTrialsTotal, nAssosRequired} = getTrialInfo(trial_order);
    console.log("ntrialsTotal: " + nTrialsTotal + ", nAssosRequired: " + nAssosRequired);
    wordAssos = await loadRandomWordAssos(nAssosRequired);
    console.log("wordAssos: " + JSON.stringify(wordAssos));
    console.log("Loading wordAssos into Unity.");
    var intoUnity = JSON.stringify(wordAssos);
    intoUnity = intoUnity.split('"').join(''); //get rid of "", this can be unity side but this side saves time and it is also easier. 
  	console.log("intoUnity from loadIntoUnity(): " + JSON.stringify(intoUnity));
    unityContext.send('Dictionary', 'Load', intoUnity);

    //unityContext.send('Triggers', 'LoadTriggers', triggers); //not needed currently
    //unityContext.send('iti_low', 'LoadITILow', iti_low); //not needed currently
    //unityContext.send('iti_high', 'LoadITIHigh', iti_high); //not needed currently

  }

  async function startOpenBCI(openbci, trialType, trialIndex){
    // start up openbci stream
    var array = ["Trial Number: " + trialIndex + ", Trial Type: " + trialType];
    await openbci.startTrial(timing, array);
    console.log("Openbci finished trial number " + trialIndex);
    // make prediction using array
  }

  return (
    <div className="BaselinePage">
        <Grid
            container
            direction="column"
            className="baselinePageContainer"
        />
        {isSignedIn 
            ? <Header/>
            : null
        }
        <Grid className="body" container direction="column" alignitems="relative">
            <button onClick={testWorker}> test eegWorker </button>
            <Fragment>
            <div>
                {showUnity === true ? (
                <Unity className="windowGame" unityContext={unityContext} />
                ) : (
                <div />
                )}
            </div>
            </Fragment>
        </Grid>
    </div>
  );
}

export default UnityPage