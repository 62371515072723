import './styles.css';
// import Button from '@material-ui/core/Button';
import React from 'react';
import ButtonSponsor from '../../Components/ButtonSponsor';
import { Link } from '@material-ui/core';


function HomeCard(props) {
    return (
        <div className="sponsor-row">
            <div className="card-background">
                <div className="image-background-bci"></div>
                <div className="container-text">
                    <div className="sponsor-title">OpenBCI</div>
                    <div className="sponsor-p">
                        OpenBCI kindly provided us with their All-in-One Biosensing R&D Bundle, which offered the perfect way to acquire EEG data for our project.
                        This model contains a fully assembled Ultracortex Mark IV EEG Headset that we used with OpenBCI's Cyton+Daisy Biosensing Boards for 16-channels.
                    </div>
                    <div className="container-button">
                    <ButtonSponsor
                        className="home-sponsor-image"
                        link="https://openbci.com/"
                        image="sponsor_openBci.png"
                    />
                    </div>
                </div>
            </div>
            <div className="card-background">
                <div className="image-background-branch-out"></div>
                <div className="container-text">
                    <div className="sponsor-title">Branch Out Neurological Foundation</div>
                    <div className="sponsor-p">
                        The Branch Out Neurological Foundation provided us with funding, and helped us continue our goal towards an alternative learning plataform.
                        They seek to develop projects focused on neuro-tech and non-pharmaceutical options. Our use of EEG to explore learning could go on to help many by breaching learning barriers.
                    </div>
                    <div className="container-button">
                    <ButtonSponsor
                        className="home-sponsor-image"
                        link="https://www.branchoutfoundation.com/"
                        image="branchout_logo.png"
                    />
                    </div>
                </div>
            </div>
            <div className="card-background">
                <div className="image-background-uofa"></div>
                <div className="container-text">
                    <div className="sponsor-title">Research Laboratories</div>
                    <div className="sponsor-p">
                        Our team worked closely with proffesor Jacqueline Cummine's Laboratory, Dr. Kuo-Chan Sun and Avery Kostiw.
                        They provided us the resources to back up our project with up-to-date research.
                    </div>
                    {/* <ButtonSponsor
                        className="home-sponsor-image"
                        link="https://apps.ualberta.ca/directory/person/jcummine"
                        image="jcummine.png"
                    /> */}
                    <div className="sponsor-row home2-h3">
                    <Link href="https://apps.ualberta.ca/directory/person/jcummine">
                        Jacqueline Cummine
                    </Link>
                    <Link href="https://apps.ualberta.ca/directory/person/kuochan">
                        Dr. Kuo-Chan Sun
                    </Link>
                    <Link href="#">
                        Avery Kostiw
                    </Link>
                    </div>
                </div>
            </div>





        </div>
    )
}


export default HomeCard;