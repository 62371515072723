import Loading from '../../Components/Loading';

import './styles.css';

const SessionInstructions = () => {
  return (
    <>
      <h1>Instructions</h1>
      <div className="sessionCard">
        Get as many <span className="sessionHighlight">Koins</span> as possible before time runs out
      </div>
      <div className="sessionCard">
        Select the right word using{' '}
        <span class="keeptogether">
          <kbd style={{transform: 'scale(-1, 1)'}}>➜</kbd> <kbd>➜</kbd>
        </span>{' '}
        on your keyboard
      </div>
      <br />
      <Loading text="Loading game..." size={100} />
    </>
  );
};

export default SessionInstructions;
