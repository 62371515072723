const TrialTypes = {
    oddball: 1,
    standard: 0,
}
  
const AnswerChoices = {
    timeout: 0,
    clicked: 1,
}

export { TrialTypes, AnswerChoices}